<template>
    <dl
        :class="{'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4': (props.cols === null || props.cols === undefined), 'grid-cols-1': props.cols === 1, 'grid-cols-2': props.cols === 2, 'grid-cols-3': props.cols === 3, 'grid-cols-4': props.cols === 4}"
        class="text-gray-900 grid">
        <div v-for="item in items" :key="item.title" class="flex flex-col py-3 border-gray-200 border-b px-1">
            <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">{{ item.title }}</dt>
            <dd v-if="item.text" class="text-lg font-semibold break-words flex-wrap whitespace-pre-wrap">
                <a v-if="item.href" :href="item.href">{{ item.text }}</a>
                <span v-else v-text="item.text"></span>
                <div v-if="item.useSlot ?? false">
                    <slot :name="item.slotId ?? 'default'"></slot>
                </div>
            </dd>
        </div>
    </dl>
</template>

<script lang="ts" setup>

import {DescriptionListItem} from './DescriptionList';

const props = withDefaults(defineProps<{
    items: DescriptionListItem[],
}>(), {});

</script>

<style scoped>

</style>
