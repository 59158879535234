<template>
    <v-field-wrapper :id="props.id" :hint="hint" :label="label">
        <div class="flex flex-col ml-10 items-center text-right">
            <div v-for="(item, index) in modelValue" :key="index" class="flex flex-row w-full text-left">
                <slot name="default" :value="item" :updateValue="updateValue(index)" :errors="arrayErrors[index] ?? {}"></slot>
                <div class="flex flex-row space-x-2 justify-center ml-2 items-center">
                    <slot name="actions" :index="index"></slot>
                    <v-tooltip text="Delete Row" position="left">
                        <template #activator>
                            <v-icon-button @click="deleteItem(index)">
                                <i class="fa fa-trash"></i>
                            </v-icon-button>
                        </template>
                    </v-tooltip>


                </div>
            </div>
            <div class="w-full">
                <v-button @click="addNewItem">Add new item</v-button>
            </div>
        </div>
    </v-field-wrapper>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';
import {cloneDeep} from 'lodash';
import VTooltip from '../../../../tools/watson/resources/js/src/components/Atoms/VTooltip.vue';
import VIconButton from '../../../../tools/watson/resources/js/src/components/Atoms/VIconButton.vue';

const props = defineProps<{
    id: string,
    label: string,
    hint: string,
    modelValue: {[key: string]: string|number}[],
    template: {[key: string]: string|number},
    errors?: {[key: string]: string[]}
}>();

const updateValue = (index: number) => {
    return (key: string, value: any) => {
        dynamicValue.value[index][key] = value;
    };
};

const dynamicValue = computed<{[key: string]: string|number}[]>({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const addNewItem = () => {
    dynamicValue.value.push(cloneDeep(props.template));
};
const deleteItem = (index: number) => {
    dynamicValue.value.splice(index, 1);
};

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: {[key: string]: string|number}[]): void
}>();

const arrayErrors = computed(() => {
    let items: {[key: number]: { [key: string]: string }} = {};
    // We have a list of errors that contain things like `item.0.sku: ['error1', 'error2']`. We need to extract these to
    // {0: {sku: ['error1', 'error2'], quantity: ['err1']}, ...}.
    if(props.errors) {
        for(let key in props.errors) {
            // If the error key (items.0.sku) starts with items., we will proceed
            if(key.startsWith(props.id + '.')) {
                // Extract the index number (e.g. 0 from items.0.sku)

                let errorIndex: number = parseInt(key.substring((props.id + '.').length, key.indexOf('.', (props.id + '.').length)));
                if(key.startsWith(props.id + '.' + errorIndex.toString() + '.')) {
                    // If the error index (0) does not yet exist in items, we shall create it as an empty object
                    items[errorIndex] = items[errorIndex] ?? {};

                    // Get the error key (sku) from the error key (items.0.sku)
                    let errorKey: string = key.substring((props.id + '.' + errorIndex.toString() + '.').length);

                    items[errorIndex][errorKey] = props.errors[key].join('. ');
                }
            }
        }
    }

    return items;
});

</script>

<style scoped>

</style>
