<template>
    <div class="group relative">
        <slot name="activator">

        </slot>

        <div class="z-100 relative" v-if="active">
            <div class="opacity-0 shadow transition-opacity group-hover:opacity-100 absolute w-max whitespace-pre pointer-events-none rounded"
                 :class="classes">
                <slot>
                    <span
                        class="normal-case bg-gray-900 px-2 py-1 text-sm font-medium text-gray-50">
                        {{text}}
                    </span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import {computed} from 'vue';

const props = withDefaults(defineProps<{
    active?: boolean;
    text?: string|null;
    position?: 'top left'|'top right'|'bottom left'|'bottom right'|'top'|'bottom'|'left'|'right'|'center'
}>(), {
    active: true,
    text: null,
    position: 'top'
});

const classes = computed<string>(() => {
    let positions: string[] = props.position.split(' ');
    let yclass: string = '-top-7';
    let xclass: string = 'left-0';

    if(positions.includes('top')) {
        yclass = '-top-7';
    } else {
        yclass = '-bottom-7';
    }

    if(positions.includes('left')) {
        xclass = 'right-0';
    } else if(positions.includes('right')) {
        xclass = 'left-0';
    } else {
        xclass = 'left-1/2 -translate-x-1/2';
    }
    return yclass + ' ' + xclass;
});


</script>

<style scoped>

</style>
