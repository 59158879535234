<template>
    <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div class="flex flex-1 justify-between sm:hidden">
            <a href="#" @click="dynamicPage = dynamicPage - 1"
               class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
            <a href="#" @click="dynamicPage = dynamicPage + 1"
               class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
        </div>
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <v-select v-model="dynamicPerPage"
                          id="per_page_selection"
                          label="Items per page"
                          :options="perPageOptions">
                </v-select>
            </div>
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium">{{ paginator.from }}</span>
                    to
                    <span class="font-medium">{{ paginator.to }}</span>
                    of
                    <span class="font-medium">{{ paginator.total }}</span>
                    results
                </p>
            </div>
            <div>
                <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <button type="button" @click="dynamicPage = dynamicPage - 1"
                            class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                        <span class="sr-only">Previous</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </button>

                    <span v-for="link in paginationLinks">
                        <button
                            type="button"
                            v-if="link.label !== '...'"
                            @click="dynamicPage = link.page"
                            :aria-current="link.active ? 'page' : ''"
                            class="relative inline-flex items-center px-4 py-2 text-sm font-semibold"
                            :class="{
                                'z-10 bg-indigo-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600': link.active,
                                'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0': !link.active,
                            }">{{ link.label }}</button>
                        <a v-else href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                            {{link.label}}
                        </a>
                    </span>

                    <a href="#" @click="dynamicPage = dynamicPage + 1"
                       class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                        <span class="sr-only">Next</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import {Paginator} from '../../types/Paginator';
import {computed} from 'vue';
import VSelect from '../Select/VSelect.vue';

interface CustomPaginationLinks {
    label: string;
    page: number | null;
    active: boolean;
}

const props = defineProps<{
    paginator: Paginator<any>
    page: number;
    perPage: number;
}>();

const perPageOptions = computed<{
    label: string;
    value: string;
}[]>(() => {
    let pageOptions = [
        ...new Set([
            5, 10, 25, 50, 100, props.perPage
        ])
    ];

    pageOptions.sort((a, b) => a - b);

    return pageOptions.map((perPage) => {
        return {
            label: perPage.toString(),
            value: perPage.toString()
        };
    });
});

const paginationLinks = computed<CustomPaginationLinks[]>(() => {
    return props.paginator.links
        .filter(l => !l.label.includes('Next') && !l.label.includes('Previous'))
        .map(l => {
            return {
                label: l.label,
                page: l.url ? parseInt(l.url.split('page=')[1]) : null,
                active: l.active
            };
        });
});

const emit = defineEmits<{
    (e: 'update:page', page: number): void
    (e: 'update:perPage', perPage: number): void
}>();

const dynamicPage = computed<number>({
    get() {
        return props.page;
    },
    set(value) {
        if (value < 1) {
            emit('update:page', 1);
        } else if (value > props.paginator.last_page) {
            emit('update:page', props.paginator.last_page);
        } else {
            emit('update:page', value);
        }
    }
});

const dynamicPerPage = computed<number>({
    get() {
        return props.perPage;
    },
    set(value) {
        if (value < 1) {
            emit('update:perPage', 1);
        } else {
            emit('update:perPage', value);
        }
    }
});

</script>

<style scoped>

</style>
