<template>
    <li>
        <div class="flex items-center flex-row pb-2" v-if="props.title">
            <img v-if="showImage" class="flex-shrink-0 object-cover mx-1 rounded-full w-9 h-9" src="./../../../images/user.png" alt="avatar">
            <div class="mx-1">
                <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200" v-if="title">{{ title }}</h1>
                <p class="text-sm text-gray-500 dark:text-gray-400" v-if="subtitle">{{ subtitle }}</p>
            </div>
        </div>
    </li>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
    title?: string|null
    showImage?: boolean,
    subtitle?: string|null
}>(), {
    title: null,
    showImage: false,
    subtitle: null
});

</script>

<style scoped>

</style>
