<template>

    <span v-if="type === CellTypes.Text">
        {{actualData}}
    </span>
    <span v-else-if="type === CellTypes.Boolean">
        <i v-if="actualData" class="fas fa-check"></i>
        <i v-else class="fas fa-times"></i>
    </span>
    <span v-else-if="type === CellTypes.Date">
        <v-format-date :date="actualData" :show-time="false"></v-format-date>
    </span>
    <span v-else-if="type === CellTypes.DateAndTime">
        <v-format-date :date="actualData" :show-time="true" :show-seconds="false"></v-format-date>
    </span>
    <span v-else-if="type === CellTypes.DateAndTimeWithSeconds">
        <v-format-date :date="actualData" :show-time="true" :show-seconds="true"></v-format-date>
    </span>
    <span v-else-if="type === CellTypes.Link">
        <a :href="actualData" target="_blank">
            <i class="fas fa-link"></i>
        </a>
    </span>

</template>

<script setup lang="ts">

import {CellTypes} from '../../types/Table';
import VFormatDate from '../FormatDate/VFormatDate.vue';
import {computed, unref} from 'vue';

const props = defineProps<{
    data: any,
    columnKey: string,
    type: CellTypes
}>();

const actualData = computed<any>(() => {
    //https://stackoverflow.com/a/18984481
    let parts: string[] = props.columnKey.split('.');
    let parent: { [key: string]: any } = unref(props.data);
    let currentPart: string = '';

    for (let i = 0, length = parts.length; i < length; i++) {
        currentPart = parts[i];
        if(parent && parent.hasOwnProperty(currentPart)) {
            parent = parent[currentPart];
        }
    }
    return parent;
});

</script>

<style scoped>

</style>
