<template>
    <v-table
        v-model:group-by="groupBy"
        v-model:sort-by="sortBy"
        v-model:sort-by-asc="sortByAsc"
        :actions="actions"
        :columns="columns"
        :data="transformedData"
        :model-value="modelValue"
        :selectable="selectable"
        :selectable-key="selectableKey"
        @clearFilter="clearFilter"
        @startFilter="goToUrl"
        :colour-key="colourKey"
        @update:model-value="$emit('update:modelValue', $event)">
        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData"/>
        </template>
    </v-table>
    <v-paginator v-model:page="page" v-model:per-page="perPage" :paginator="paginator"></v-paginator>
</template>

<script lang="ts" setup>

import {Paginator} from '../../types/Paginator';
import {Column} from '../../types/Table';
import {computed, watch} from 'vue';

const props = withDefaults(defineProps<{
    paginator: Paginator<any>,
    columns: Column[],
    transform?: null | CallableFunction,
    queryParams: { [key: string]: any },
    actions?: boolean,
    modifyUrl?: boolean,
    selectableKey?: string,
    selectable?: boolean | (() => boolean),
    modelValue?: { [key: string | number]: any }[],
    colourKey?: null | string
}>(), {
    selectable: false,
    selectableKey: 'id',
    colourKey: null,
});

const page = computed<number>({
    get() {
        return props.paginator.current_page;
    },
    set(value) {
        let query = props.queryParams;
        query.page = value;
        emit('update:query-params', query);
    }
});

const perPage = computed<number>({
    get() {
        return props.paginator.per_page;
    },
    set(value) {
        let query = props.queryParams;
        query.per_page = value;
        emit('update:query-params', query);
    }
});

const sortBy = computed<string | null>({
    get() {
        return props.queryParams.sort_by;
    },
    set(value) {
        let query = props.queryParams;
        query.sort_by = value;
        emit('update:query-params', query);
    }
});

const groupBy = computed<string | null>({
    get() {
        return props.queryParams.group_by || null;
    },
    set(value) {
        let query = props.queryParams;
        query.group_by = value;
        emit('update:query-params', query);
    }
});

const sortByAsc = computed<boolean>({
    get() {
        return props.queryParams.sort_by_asc;
    },
    set(value) {
        let query = props.queryParams;
        query.sort_by_asc = value;
        emit('update:query-params', query);
    }
});

const emit = defineEmits<{
    (event: 'update:query-params', value: { [key: string]: any }): void,
    (event: 'clearFilter', key: string): void,
    (event: 'update:modelValue', value: { [key: string | number]: any }[]): void,
}>();

const transformedData = computed<any[]>(() => {
    if (props.transform) {
        return props.transform(props.paginator.data);
    }
    return props.paginator.data;
});

const generatedUrl = computed<string>(() => {
    const url = new URL(window.location.href);
    for (let key in props.queryParams) {
        if (typeof props.queryParams[key] === 'boolean') {
            url.searchParams.set(key, props.queryParams[key] ? '1' : '0');
        } else if (props.queryParams[key] === null) {
            url.searchParams.delete(key);
        } else {
            url.searchParams.set(key, props.queryParams[key]);
        }
    }
    return url.toString();
});

watch(generatedUrl, () => {
    goToUrl();
});

const goToUrl = () => {
    if (props.modifyUrl) {
        window.location.assign(generatedUrl.value);
    }
};

const clearFilter = (key: string) => {
    let query = props.queryParams;
    query[key] = null;
    emit('update:query-params', query);
    emit('clearFilter', key);
};

</script>

<style scoped>

</style>
