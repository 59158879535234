<template>
    <div class="py-3 flex items-center justify-between">
        <div class="flex-1 flex justify-between sm:hidden">
            <button
                v-if="orders.current_page < totalPages && orders.current_page !== 1"
                @click="() => { 
                    clearSelections();
                    fetchOrders({page: orders.current_page - 1, per_page: orders.per_page});
                }"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                Previous
            </button>
            <button
                v-if="orders.current_page < totalPages && orders.current_page !== totalPages"
                @click="() => {
                    clearSelections();
                    fetchOrders({page: orders.current_page + 1});
                }"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                Next
            </button>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <v-select 
                    v-model="perPage"
                    id="per_page_selection"
                    label="Items per page"
                    :options="perPageOptions" 
                />
            </div>
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    <template v-if="orders.links.length > 1">
                        <span class="font-medium">{{ orders.from }}</span>
                        to
                        <span class="font-medium">{{ orders.to }}</span>
                        of
                    </template>
                    <span class="font-medium">{{ orders.total }}</span>
                    {{ orders.total > 1 || orders.total == 0 ? 'results' : 'result' }}
                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button 
                        v-if="orders.prev_page_url" 
                        @click="() => {
                            clearSelections();
                            fetchOrders({page: orders.current_page - 1, per_page: orders.per_page});
                        }"
                        class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                        <span class="sr-only">Previous</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path 
                                fill-rule="evenodd"
                                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                    <button 
                        v-for="link in pageLinks" 
                        :key="link.label" 
                        @click="() => {
                            clearSelections();
                            fetchOrders({ page: parseInt(link.label), per_page: orders.per_page });
                        }"
                        :class="(link.active ? 
                            'z-10 bg-indigo-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' 
                            : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        ) + 'relative inline-flex items-center px-4 py-2 text-sm font-semibold'"
                    >
                        {{ link.label }}
                    </button>
                    <button
                        v-if="orders.next_page_url" 
                        @click="() => {
                            clearSelections();
                            fetchOrders({page: orders.current_page + 1, per_page: orders.per_page });
                        }"
                        class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                        <span class="sr-only">Next</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path 
                                fill-rule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { computed } from 'vue';
/**
 * Local dependencies
 */
import { GetOrderIndexRequest, GetOrderIndexResponse } from './Types/orders';
import { consoleDevLog } from '../../Helpers/console-utils';

/**
 * Props
 */
const props = withDefaults(defineProps<{
    orders: GetOrderIndexResponse;
    fetchOrders: (queryParams: GetOrderIndexRequest) => void;
    clearSelections?: () => void
}>(), {
    clearSelections: () => consoleDevLog('No clearSelections function passed to AjaxPagination component for BoxOrderViewer')
});

/**
 * Computed properties
 */
const perPageOptions = computed<{
    label: string;
    value: string;
}[]>(() => {
    let pageOptions = [
        ...new Set([
            5, 10, 25, 50, 100, props.orders.per_page
        ])
    ];

    pageOptions.sort((a, b) => a - b);

    return pageOptions.map((amount) => {
        return {
            label: amount.toString(),
            value: amount.toString()
        };
    });
});

const totalPages = computed<number>(() => props.orders ? Math.ceil(props.orders.total / props.orders.per_page) : 0);
const currPage = computed<number>(() => props.orders ? props.orders.current_page : 1);
const perPage = computed<number>({
    get() {
        return props.orders ? props.orders.per_page : 10;
    },
    set (value: number) {
        props.clearSelections();

        if (value < 1) {
            props.fetchOrders({page: currPage.value, per_page: 10});
        } else {
            props.fetchOrders({page: currPage.value, per_page: value});
        }
    }
});
const pageLinks = computed(() => {
    const { links } = props.orders;
    return links.filter(link => !link.label.includes('Previous') && !link.label.includes('Next'));
});
</script>
