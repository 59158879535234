<template>
    <div class="w-full my-5">
        <VBoxOrderViewer
            :data="orders && arrayWithValues(orders.data) ? orders.data : []"
            :fetching="fetching"
            :selectMode="selectMode"
            v-model:selectedOrder="order"
            v-model:selectedDispatch="dispatch"
        />
        <AjaxPagination 
            v-if="orders && withPagination"
            :orders="orders"
            :fetchOrders="fetchOrders"
            :clearSelections="clearSelections"
        />
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { 
    onMounted,
    ref,
    watch
} from 'vue';

/**
 * External dependencies
 */
import isEqual from 'lodash/isEqual';

/**
 * Local dependencies
 */
import { getOrders } from '../Helpers/request-utils';
import { GetOrderIndexRequest, GetOrderIndexResponse, Order } from '../Types/orders';
import { ExtractedLaravelError } from '../Types/error-handling';
import { consoleDevLog } from '../../../Helpers/console-utils';
import { arrayWithValues } from '../../../Helpers/array-utils';
import { Dispatch } from '../Types/dispatches';

/**
 * Props
 */
const props = withDefaults(defineProps<{
    orderIds?: number[];
    withPagination?: boolean;
    selectMode?: boolean;
}>(), {
    withPagination: true,
    selectMode: false
});
const orders = ref<GetOrderIndexResponse|undefined>(undefined);
const order = defineModel<Order['id']|null>('order');
const dispatch = defineModel<Dispatch['id']|null>('dispatch');
const fetching = ref<boolean>(false);


// Functions
/**
 * Fetch Orders
 * 
 * Retrieves the order results based on the current query parameters. Allows for pagination.
 * 
 * @param {object|undefined} newParams An object containing new parameters that modify the query. 
 */
const fetchOrders = (newParams?: GetOrderIndexRequest) => {
    fetching.value = true;

    let queryParams: GetOrderIndexRequest = {
        includes: [
            'currency', 
            'returns', 
            'returns.items', 
            'dispatches.dispatchItems',
            'dispatchRequests',
            'dispatchRequests.dispatchRequestItems',
            'dispatchRequests.dispatches:id,dispatch_request_id'
        ]
    };

    if (props.orderIds) {
        queryParams.order_ids = props.orderIds;
    }

    if (newParams) {
        queryParams = {
            ...queryParams,
            ...newParams
        };
    }

    getOrders(queryParams)
        .then(response => orders.value = response)
        .catch((error: ExtractedLaravelError) => {
            consoleDevLog('Call to getOrders from whithin VBoxOrderViewerController failed.');
            consoleDevLog(error);
        })
        .finally(() => fetching.value = false);
};

/**
 * Clear Selections
 * 
 * Clears both selected values for order and dispatch when in select mode. Used by the ajax pagination
 * so that an order id from a previous page cannot remain selected on page change.
 */
const clearSelections = () => {
    order.value = undefined;
    dispatch.value = undefined;
};

/**
 * Lifecycle Hooks
 */
onMounted(() => {
    fetchOrders();
});

watch(
    () => props.orderIds ?? [], 
    (newOrderIds: number[], oldOrderIds: number[]) => { 
        if (! isEqual(newOrderIds, oldOrderIds)) {
            fetchOrders({page: 1});
        }
    }
);
</script>
