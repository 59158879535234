<template>
    <div class="flex gap-x-4 justify-between items-start border-b border-gray-200 pb-4">
        <div class="flex flex-col items-start justify-start">
            <h3 
                class="font-semibold"
                :class="{
                    'text-white': selected,
                    'text-gray-900': !selected
                }"
            >{{ order.internal_order_reference }}</h3>
            <time 
                class="text-xs"
                :class="{
                    'text-white': selected,
                    'text-gray-400': !selected
                }"
                :datetime="order.created_at"
            >
                {{ moment(order.created_at).format('DD/MM/YYYY @ HH:mm') }}
            </time>
            <span class="text-2xl font-semibold pt-2">
                {{ calculateOrderValue(order.total_amount_cents, order.currency.name) }}
            </span>
        </div>
        <VLozenge :variant="calculateOrderStatusLozengeVariant(order.status)">{{ startCase(order.status) }}</VLozenge>
    </div>

    <dl class="divide-y divide-gray-200 text-sm">
        <div class="flex justify-between gap-x-4 py-4" v-if="arrayWithValues(order.order_products)">
            <dt 
                class="w-1/3"
                :class="{
                    'text-white': selected,
                    'text-gray-400': !selected
                }"
            >
                Items
            </dt>
            <dd class="text-gray-900 w-2/3 flex flex-wrap leading-tight">
                <span 
                    class="w-full block text-right"
                    :class="{
                        'text-white': selected,
                        'text-gray-900': !selected
                    }"
                    v-for="item in order.order_products"
                >
                    {{ `${item.quantity} x ${item.product.sku}` }}
                </span>
            </dd>
        </div>

        <div class="flex justify-between gap-x-4 py-4" v-if="!minified && order.customer && order.customer_shipping_address">
            <dt 
                class="w-1/3"
                :class="{
                    'text-white': selected,
                    'text-gray-400': !selected
                }"
            >
                Shipping Address
            </dt>
            <dd 
                class="w-2/3 flex flex-wrap leading-tight"
                :class="{
                    'text-white': selected,
                    'text-gray-900': !selected
                }"
            >
                <span class="w-full block text-right">
                    {{ order.customer.name }}
                </span>
                <span class="w-full block text-right">{{ order.customer_shipping_address.address_line_1 }}</span>
                <span class="w-full block text-right">{{ order.customer_shipping_address.address_line_2 }}</span>
                <span class="w-full block text-right">{{ order.customer_shipping_address.city }}</span>
                <span class="w-full block text-right">{{ order.customer_shipping_address.postal_code }}</span>
            </dd>
        </div>

        <div class="flex justify-between gap-x-4 py-4" v-if="!minified && order.shipping_region">
            <dt 
                class="w-1/3"
                :class="{
                    'text-white': selected,
                    'text-gray-400': !selected
                }"
            >
                Shipping Region
            </dt>
            <dd 
                class="w-2/3 flex flex-wrap"
                :class="{
                    'text-white': selected,
                    'text-gray-900': !selected
                }"
            >
                <span class="w-full block text-right">{{ order.shipping_region }}</span>
            </dd>
        </div>

        <div class="flex justify-between gap-x-4 py-4" v-if="!minified">
            <dt 
                class="text-gray-400 w-1/3"
                :class="{
                    'text-white': selected,
                    'text-gray-400': !selected
                }"
            >
                Contact
            </dt>
            <dd 
                class="w-2/3 flex flex-wrap leading-tight"
                :class="{
                    'text-white': selected,
                    'text-gray-900': !selected
                }"
            >
                <span class="flex w-full items-center gap-x-2 justify-end" v-if="order.customer_email">
                    <i 
                        class="fa-solid fa-at text-xs"
                        :class="{
                            'text-white': selected,
                            'text-gray-400': !selected
                        }"
                    ></i>
                    {{ order.customer_email.email }}
                </span>
                <span class="flex w-full items-center gap-x-2 justify-end" v-if="order.customer_phone">
                    <i 
                        class="fa-solid fa-phone text-xs"
                        :class="{
                            'text-white': selected,
                            'text-gray-400': !selected
                        }"
                    ></i>
                    {{ order.customer_phone.phone }}
                </span>
            </dd>
        </div>
    </dl>

    <div class="rounded-b-lg border-t border-gray-200 flex divide-x justify-center -mr-4 -ml-4 -mb-4 mt-auto" v-if="!minified && !selectMode">
        <a 
            :href="`/tools/watson#/share?model=order&id=${order.id}&reference=${order.internal_order_reference}`" 
            class="inline-flex items-center justify-center gap-x-2 py-2 font-semibold grow"
            v-if="!selectMode"
        >
            <i class="fa-regular fa-eye w-5 text-gray-400" aria-hidden="true"></i>
            <span>VIEW FULL ORDER</span>
        </a>
    </div>

    <div class="rounded-b-lg border-t border-gray-200 flex divide-x justify-center -mr-4 -ml-4 -mb-4 mt-auto" v-if="selectMode">
        <button
            class="inline-flex items-center justify-center gap-x-2 py-2 font-semibold grow"
            @click="() => selectOrder(order.id)"
            type="button"
        >
            <i 
                class="fa-regular fa-eye w-5"
                :class="{
                    'text-white': selected,
                    'text-gray-400': !selected
                }" 
                aria-hidden="true"
            ></i>
            <span>{{ selected ? 'DESELECT ORDER' : 'SELECT ORDER'}}</span>
        </button>
    </div>
</template>

<script setup lang="ts">
/**
 * External dependencies
 */
import isNumber from 'lodash/isNumber';
import startCase from 'lodash/startCase';
import moment from 'moment';

/**
 * Local dependencies
 */
import { Currencies } from '../../Types/currencies';
import { LozengeProps } from '../Lozenge/props';
import { Order, OrderStatus } from './Types/orders';
import { arrayWithValues } from '../../Helpers/array-utils';
import { consoleDevLog } from '../../Helpers/console-utils';

/**
 * Props
 */
withDefaults(defineProps<{
    order: Order;
    minified?: boolean;
    selectMode?: boolean;
    selected?: boolean;
    selectOrder?: (orderId: number) => void
}>(), {
    minified: true,
    selectMode: false,
    selected: false,
    selectOrder: (orderId: number) => consoleDevLog(`No order select function passed to VBoxOrder component. OrderId ${orderId}`)
});

// Functions
/**
 * Calculate Order value
 * 
 * Takes the passed number and converts it to it's nearest whole currency unit (pound, dollar etc.).
 * Returns a currency formatted string.
 *  
 * @param {number} cents The amount of cents or pence to convert to whole currency unit.
 */
const calculateOrderValue = (cents: number, currency: Currencies): string => {
    let value = 0;
    const currencyOptions: Intl.NumberFormatOptions = {
        style: 'currency', 
        currency, 
        currencyDisplay: 'narrowSymbol' 
    };

    if (isNumber(cents)) {
        value = cents / 100;
    }

    return value.toLocaleString(undefined, currencyOptions);
};

/**
 * Calculate Order Status Lozenge Variant
 * 
 * Translates the current order status into a variant that defines the colour and styling of the
 * status lozenge shown.
 * 
 * @param {string} orderStatus The order status to translate into a lozenge variant.
 */
const calculateOrderStatusLozengeVariant = (orderStatus: OrderStatus): LozengeProps['variant'] => {
    switch (orderStatus) {
    case 'Cancelled':
    case 'Failed':
    case 'Preorder - Cancelled':
    case 'Preorder - Failed':
    case 'Refunded':
        return 'error';
    case 'On Hold':
    case 'Locked':
        return 'info';
    case 'Complete':
    case 'Fulfilled':
        return 'success';
    case 'Preorder':
        return 'warning';
    case 'Draft':
    default: 
        return 'default';
    }
};
</script>
