<template>

    <div class="mx-auto p-8 bg-white rounded-lg shadow-md pt-8">

        <div>
            <!-- Search Field -->
            <v-select :searchable="true"
                      v-model="foundAddressPlaceId"
                      :options="foundAddressOptions"
                      label="Search for an address"
                      :search-function="searchForAddress"
                      hint="Search for an address by any part of the address"
                      :multiple="false"
                      :filterable="false"
                      id="addressSearch">

            </v-select>

            <div v-if="loadingFullAddress">
                <v-spinner />
            </div>
            <div v-else>
                <!-- Divider -->
                <div class="flex items-center justify-center mt-4">
                    <span class="text-sm text-gray-500">or enter manually</span>
                </div>


                <v-text id="address_line_1" label="Address Line 1"
                        v-model="addressLine1"
                        :error="props.errors['address_line_1'] ?? null"
                        hint="Enter the customer's address."
                        :required="true"
                        :autofocus="true">

                </v-text>

                <v-text id="address_line_2" label="Address Line 2"
                        v-model="addressLine2"
                        :error="props.errors['address_line_2'] ?? null"
                        hint="Enter the customer's address."
                        :required="false"
                        :autofocus="false">

                </v-text>

                <v-text id="city" label="City"
                        v-model="city"
                        :error="props.errors['city'] ?? null"
                        hint="Enter the customer's city."
                        :required="true"
                        :autofocus="false">

                </v-text>

                <v-text id="state" label="State"
                        v-model="state"
                        :error="props.errors['state'] ?? null"
                        hint="Enter the customer's state."
                        :required="false"
                        :autofocus="false">

                </v-text>

                <v-text id="postal_code" label="Postal Code"
                        v-model="postalCode"
                        :error="props.errors['postal_code'] ?? null"
                        hint="Enter the customer's postal code."
                        :required="true"
                        :autofocus="false">

                </v-text>

                <v-country-select
                    id="country_select"
                    label="Country"
                    :error="props.errors['country_id'] ?? null"
                    v-model="countryId"
                    hint="Enter the customer's country."
                    :required="true"
                >

                </v-country-select>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">

import VText from '../Text/VText.vue';
import {ref, watch} from 'vue';
import VSelect from '../Select/VSelect.vue';
import {SelectOption} from '../../Types/forms';
import axios from 'axios';
import {route} from 'ziggy-js';
import VSpinner from '../Spinner/VSpinner.vue';

const addressLine1 = defineModel<string|null>('addressLine1');
const addressLine2 = defineModel<string|null>('addressLine2');
const city = defineModel<string|null>('city');
const state = defineModel<string|null>('state');
const postalCode = defineModel<string|null>('postalCode');
const countryId = defineModel<number|null>('countryId');

const foundAddressOptions = ref<SelectOption[]>([]);

const foundAddressPlaceId = ref<string|null>(null);

const props = defineProps<{
    errors: { [key: string]: string }
}>();

interface FoundAddressOption {
    text: string;
    place_id: string;
}

const searchForAddress = (query: string | null): Promise<SelectOption[]> => {
    // if(query === null || query.length < 3) {
    //     console.log('NOWT');
    //     return Promise.resolve([]);
    // }
    return new Promise((resolve, reject) => {
        axios.get(route('api.address-search.index', {
            query: query,
        }))
            .then(response => {
                console.log(response.data);
                console.log(response.data.length);
                resolve(response.data.map((foundAddress: FoundAddressOption) => {
                    return {
                        label: foundAddress.text,
                        value: foundAddress.place_id
                    };
                }));
                // foundAddressOptions.value = response.data.map((foundAddress: FoundAddressOption) => {
                //     return {
                //         label: foundAddress.text,
                //         value: foundAddress.place_id
                //     };
                // });
                // resolve(foundAddressOptions.value);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const loadingFullAddress = ref<boolean>(false);

watch(foundAddressPlaceId, (placeId) => {
    if(placeId === null) {
        return;
    }
    loadingFullAddress.value = true;
    axios.get(route('api.address-search.lookup', {
        placeId: placeId,
    }))
        .then(response => {
            addressLine1.value = response.data.address_line_1;
            addressLine2.value = response.data.address_line_2;
            city.value = response.data.city;
            state.value = response.data.state;
            postalCode.value = response.data.postal_code;
            countryId.value = response.data.country_id;
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            loadingFullAddress.value = false;
        });
    // Look up the full address and populate the fields
});


</script>

<style scoped>

</style>