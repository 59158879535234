<template>

    <div role="alert" class="pt-4" v-if="Object.keys(errors).length > 0">
        <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
            Form errors
        </div>

        <div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
            <p>Please fix the following errors before continuing.</p>

            <ul class="text-slate-700 text-sm leading-6">
                <li v-for="(error, index) in brokenDownErrors" :key="index" :class="{'ml-4': error.type === 'error'}">
                    <p v-if="error.type === 'header'" class="font-bold block py-1 text-sky-500 dark:text-sky-400">
                        {{error.text}}
                    </p>
                    <p class="group flex items-start py-1 text-sky-500 dark:text-sky-400" v-else>
                        <svg width="3" height="24" viewBox="0 -9 3 24"
                             class="mr-2 text-slate-400 overflow-visible group-hover:text-slate-600 dark:text-slate-600 dark:group-hover:text-slate-500">
                            <path d="M0 0L3 3L0 6" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round">
                            </path>
                        </svg>
                        {{error.text}}
                    </p>
                </li>
            </ul>

        </div>
    </div>
</template>

<script setup lang="ts">

import {computed} from 'vue';

const props = defineProps<{
    errors: { [key: string]: string[] }
}>();

const brokenDownErrors = computed(() => {
    let brokenDownErrors: {
        text: string,
        type: 'header'|'error'
    }[] = [];

    for(let key in props.errors) {
        brokenDownErrors.push({
            text: key,
            type: 'header'
        });
        for(let error in props.errors[key]) {
            brokenDownErrors.push({
                text: props.errors[key][error],
                type: 'error'
            });
        }
    }
    return brokenDownErrors;
});

</script>

<style scoped>

</style>
