<template>
    <v-text
        :id="props.id + '-name'"
        label="Name"
        hint="The customer's name"
        :pale="pale"
        :modelValue="modelValue.name"
        @update:modelValue="(newVal) => setAddress(newVal, 'name')"
        :required="requireds?.name ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
    <v-text
        :id="props.id + '-address-line-1'"
        label="Address line 1"
        hint="The customer's address line 1"
        :pale="pale"
        :modelValue="modelValue.address_line_1"
        @update:modelValue="(newVal) => setAddress(newVal, 'address_line_1')"
        :required="requireds?.address_line_1 ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
    <v-text
        :id="props.id + '-address-line-2'"
        label="Address line 2"
        hint="The customer's address line 2"
        :pale="pale"
        :modelValue="modelValue.address_line_2"
        @update:modelValue="(newVal) => setAddress(newVal, 'address_line_2')"
        :required="requireds?.address_line_2 ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
    <v-text
        :id="props.id + '-city'"
        label="City"
        hint="The customer's city"
        :pale="pale"
        :modelValue="modelValue.city"
        @update:modelValue="(newVal) => setAddress(newVal, 'city')"
        :required="requireds?.city ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
    <v-text
        :id="props.id + '-state'"
        label="State"
        hint="The customer's state"
        :pale="pale"
        :modelValue="modelValue.state"
        @update:modelValue="(newVal) => setAddress(newVal, 'state')"
        :required="requireds?.state ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
    <v-text
        :id="props.id + '-postal-code'"
        label="Postal code"
        hint="The customer's postal code"
        :pale="pale"
        :modelValue="modelValue.postal_code"
        @update:modelValue="(newVal) => setAddress(newVal, 'postal_code')"
        :required="requireds?.postal_code ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
    <v-text
        :id="props.id + '-country-iso'"
        label="Country ISO2"
        hint="The customer's country ISO2"
        :pale="pale"
        :modelValue="modelValue.country_iso"
        @update:modelValue="(newVal) => setAddress(newVal, 'country_iso')"
        :required="requireds?.country_iso ?? false"
        @keyup="$emit('keyup', $event)"
    ></v-text>
</template>

<script setup lang="ts">
import { Address } from '../../../../tools/dispatch-requests/resources/js/types/DispatchRequest';
import VText from '../Text/VText.vue';

interface AddressFieldsRequired {
  name: boolean | null;
  address_line_1: boolean | null;
  address_line_2: boolean | null;
  city: boolean | null;
  state: boolean | null;
  postal_code: boolean | null;
  country_iso: boolean | null;
}

const props = withDefaults(
    defineProps<{
    id: string;
    label: string;
    hint?: string;
    modelValue?: Address;
    pale?: boolean | null;
    requireds?: AddressFieldsRequired | null;
  }>(),
    {
        modelValue: {
            name: null,
            address_line_1: null,
            address_line_2: null,
            city: null,
            state: null,
            postal_code: null,
            country_iso: null,
        } as Address,
        pale: false,
        requireds: null,
    }
);

const setAddress = (value: string, field: keyof Address) => {
    // debugger
    let val = props.modelValue;
    val[field] = value;
    emit('update:modelValue', val);
};

const emit = defineEmits<{
  (event: 'update:modelValue', modelValue: Address | null): void;
}>();
</script>

<style scoped></style>
