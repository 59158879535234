<template>

    <div v-if="!loadingOptions">
        <div class="flex flex-row justify-between">
            <div>
                <v-toggle
                    id="upgrade_kit_only"
                    v-model="upgradeKit"
                    hint="Change between upgrade and full kits"
                    off-label="Full KIT"
                    on-label="Upgrade KIT"></v-toggle>
            </div>
            <div class="align-middle">
                <div class="text-lg flex flex-row divide-x *:px-1 first:*:pl-0 last:*:pr-0">
                    <v-tooltip text="KIT identifier">
                        <template #activator>KIT</template>
                    </v-tooltip>
                    <v-tooltip :text="'Power: ' + selectedPowerOption.label">
                        <template #activator>{{ selectedPowerOption.value }}</template>
                    </v-tooltip>
                    <v-tooltip :text="'Display: ' + selectedDisplayOption.label">
                        <template #activator>{{ selectedDisplayOption.value }}</template>
                    </v-tooltip>
                    <v-tooltip :text="'Plug: ' + selectedPlugOption.label">
                        <template #activator>{{ selectedPlugOption.value }}</template>
                    </v-tooltip>
                    <v-tooltip :text="'PAS: ' + selectedPasOption.label">
                        <template #activator>{{ selectedPasOption.value }}</template>
                    </v-tooltip>
                    <v-tooltip :text="'Mount: ' + selectedMountOption.label">
                        <template #activator>{{ selectedMountOption.value }}</template>
                    </v-tooltip>

                    <v-tooltip v-if="!upgradeKit" text="KIT and wheel separator">
                        <template #activator>-</template>
                    </v-tooltip>
                    <v-tooltip v-if="!upgradeKit" :text="'Hub: ' + selectedHubOption.label">
                        <template #activator>{{ selectedHubOption.value }}</template>
                    </v-tooltip>
                    <v-tooltip v-if="!upgradeKit" :text="'Rim: ' + selectedRimOption.label">
                        <template #activator>{{ selectedRimOption.value }}</template>
                    </v-tooltip>
                    <v-tooltip v-if="!upgradeKit" :text="'Wheel Colour: ' + selectedWheelColourOption.label">
                        <template #activator>{{ selectedWheelColourOption.value }}</template>
                    </v-tooltip>
                </div>
            </div>
            <div class="ml-2">
                <v-button :href="skuUrl" target="_blank">View</v-button>
            </div>
        </div>
        <div class="flex flex-row w-full flex-wrap">
            <table class="border-separate border-spacing-4">
                <tr>
                    <td class="text-center font-bold text-lg" colspan="3">
                        Kit
                    </td>
                </tr>
                <tr>
                    <th>
                        <constitutent-details description="Defines the battery supplied with the kit"
                                              name="Power Pack"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="power" v-model="selectedPowerOption"
                                                :options="kitOptions.powerConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedPowerOption?.label }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <constitutent-details description="Defines the display supplied with the kit"
                                              name="Display"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="display" v-model="selectedDisplayOption"
                                                :options="kitOptions.displayConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedDisplayOption?.label }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <constitutent-details description="Defines the plug supplied with the kit"
                                              name="Plug"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="plug" v-model="selectedPlugOption"
                                                :options="kitOptions.plugConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedPlugOption?.label }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <constitutent-details description="Defines the PAS supplied with the kit"
                                              name="PAS"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="pas" v-model="selectedPasOption"
                                                :options="kitOptions.pasConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedPasOption?.label }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <constitutent-details description="Defines the mount supplied with the kit"
                                              name="Mount"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="mount" v-model="selectedMountOption"
                                                :options="kitOptions.mountConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedMountOption?.label }}
                    </td>
                </tr>

                <tr>
                    <td class="text-center font-bold text-lg" colspan="3">
                        Wheel
                    </td>
                </tr>

                <tr v-if="!upgradeKit">
                    <th>
                        <constitutent-details description="Defines the hub size of the wheel"
                                              name="Hub"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="hub" v-model="selectedHubOption"
                                                :options="kitOptions.hubConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedHubOption?.label }}
                    </td>
                </tr>
                <tr v-if="!upgradeKit">
                    <th>
                        <constitutent-details description="Defines the rim size of the wheel"
                                              name="Rim"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="rim" v-model="selectedRimOption"
                                                :options="kitOptions.rimConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedRimOption?.label }}
                    </td>
                </tr>
                <tr v-if="!upgradeKit">
                    <th>
                        <constitutent-details description="Defines the colour of the wheel"
                                              name="Wheel Colour"></constitutent-details>
                    </th>
                    <td>
                        <constitutent-selection id="wheel-colour" v-model="selectedWheelColourOption"
                                                :options="kitOptions.wheelColourConfigOption"></constitutent-selection>
                    </td>
                    <td>
                        {{ selectedWheelColourOption?.label }}
                    </td>
                </tr>
            </table>
            <div>
                <span v-if="loadingComponents">Loading components</span>
                <div v-else>
                    <table class="border-separate">
                        <tr>
                            <td class="text-center font-bold text-lg" colspan="2">
                                Contents
                            </td>
                        </tr>
                        <tr v-for="componentSku in componentSkus">
                            <th>
                                {{ componentSku.quantity }}
                            </th>
                            <td>
                                x {{ componentSku.sku }}
                            </td>
                        </tr>

                        <!--                <ul>-->
                        <!--                    <li v-for="componentSku in componentSkus">{{componentSku.quantity}} x {{ componentSku.sku }}</li>-->
                        <!--                </ul>-->
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        Loading...
    </div>


</template>

<script lang="ts" setup>

import {SelectOption} from '../../../../../resources/js/Types/forms';
import {computed, onMounted, ref, watch} from 'vue';
import ConstitutentDetails from './VersionThreeKitBuilder/ConstitutentDetails.vue';
import ConstitutentSelection from './VersionThreeKitBuilder/ConstitutentSelection.vue';
import {route} from 'ziggy-js';
import axios from 'axios';

// const props = withDefaults(defineProps<{
//     modelValue: string|null
// }>(), {
//     modelValue: null
// });

const kitOptions = ref<{
    powerConfigOption: SelectOption[],
    displayConfigOption: SelectOption[],
    plugConfigOption: SelectOption[],
    pasConfigOption: SelectOption[],
    mountConfigOption: SelectOption[],
    hubConfigOption: SelectOption[],
    rimConfigOption: SelectOption[],
    wheelColourConfigOption: SelectOption[],
}>({
    powerConfigOption: [],
    displayConfigOption: [],
    plugConfigOption: [],
    pasConfigOption: [],
    mountConfigOption: [],
    hubConfigOption: [],
    rimConfigOption: [],
    wheelColourConfigOption: [],
});

const loadingOptions = ref<boolean>(true);

onMounted(() => {
    loadOptions();
});

const loadOptions = () => {
    axios.get(route('api.tools.skus.kits.options'))
        .then((response) => {
            kitOptions.value = response.data;
            selectedPowerOption.value = kitOptions.value.powerConfigOption[0];
            selectedDisplayOption.value = kitOptions.value.displayConfigOption[0];
            selectedPlugOption.value = kitOptions.value.plugConfigOption[0];
            selectedPasOption.value = kitOptions.value.pasConfigOption[0];
            selectedMountOption.value = kitOptions.value.mountConfigOption[0];
            selectedHubOption.value = kitOptions.value.hubConfigOption[0];
            selectedRimOption.value = kitOptions.value.rimConfigOption[0];
            selectedWheelColourOption.value = kitOptions.value.wheelColourConfigOption[0];
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            loadingOptions.value = false;
        });
};

// const first = (options: SelectOption[])
const selectedPowerOption = ref<SelectOption | null>(null);
const selectedDisplayOption = ref<SelectOption | null>(null);
const selectedPlugOption = ref<SelectOption | null>(null);
const selectedPasOption = ref<SelectOption | null>(null);
const selectedMountOption = ref<SelectOption | null>(null);
const selectedHubOption = ref<SelectOption | null>(null);
const selectedRimOption = ref<SelectOption | null>(null);
const selectedWheelColourOption = ref<SelectOption | null>(null);

const upgradeKit = ref<boolean>(false);

const sku = computed<string>(() => {
    let builtSku = 'KIT'
        + selectedPowerOption.value?.value
        + selectedDisplayOption.value?.value
        + selectedPlugOption.value?.value
        + selectedPasOption.value?.value
        + selectedMountOption.value?.value;

    if (!upgradeKit.value) {
        builtSku += '-'
            + selectedHubOption.value?.value
            + selectedRimOption.value?.value
            + selectedWheelColourOption.value?.value;
    }

    return builtSku;
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string|null): void
}>();

watch(sku, (sku) => {
    emit('update:modelValue', sku);
});

const skuUrl = computed<string | null>(() => {
    return route('tools.skus.kits.show', {
        sku: sku.value,
    });
});


const componentSkus = ref<{ quantity: number, sku: string }[]>([]);

const loadingComponents = ref<boolean>(false);

watch(sku, (value) => {
    loadingComponents.value = true;
    axios.get(route('api.tools.skus.kits.breakdown', {
        kit: value,
    }))
        .then((response) => {
            componentSkus.value = response.data;
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            loadingComponents.value = false;
        });
}, {
    immediate: true
});


</script>

<style scoped>

</style>