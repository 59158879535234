<template>

    <aside id="toggleable-sidebar"
           class="shadow-[rgba(0,0,15,0.5)_4px_0px_5px_-5px] ml-0 z-50 h-full sm:h-[calc(100vh-66px)] w-full sm:max-w-[16rem] fixed top-0
               sm:top-16 overflow-y-scroll overflow-x-hidden sm:pt-2 bottom-0 transition-transform bg-white border-r
               border-gray-200 dark:bg-gray-800 dark:border-gray-700"
           aria-label="Sidebar"
           :class="{'translate-x-0 overflow-y-hidden': sidebarShowing, '-translate-x-full': !sidebarShowing}">
        <div class="px-2 pb-2 bg-white dark:bg-gray-800 relative min-h-full w-[calc(100%-1px)] flex flex-col">
            <div class="flex justify-end block w-full">

            </div>
            <div class="space-y-2 font-medium">
                <div class="flex w-full justify-end">
                    <v-icon-button @click="sidebarShowing = false" tight class="mt-1 mr-1 sm:hidden text-2xl ml-2 items-top">
                        <i class="fa fa-times text-black p-1"></i>
                    </v-icon-button>
                </div>
                <div class="flex w-full justify-end mr-2">
                    <div class="flex-grow">
                        <slot name="header">

                        </slot>
                    </div>
                </div>

            </div>
            <div class="flex-grow">
                <slot></slot>
            </div>
            <div class="relative bottom-0 w-full mb-4 mt-8">
                <div class="space-y-2 p-2 border-t">
                    <slot name="footer">

                    </slot>
                </div>
            </div>
        </div>
    </aside>
</template>

<script setup lang="ts">
import {initialise, sidebarShowing} from '../../Tools/sidebar/sidebar';
import {onMounted} from 'vue';

onMounted(() => {
    initialise();
});


</script>

<style scoped>

</style>
