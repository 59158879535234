<template>
    <ol class="items-center w-full sm:flex justify-between sm:px-4">
        <li class="flex items-center space-x-2.5 rtl:space-x-reverse"
            :class="{
                'text-blue-600 dark:text-blue-500': step.id === props.modelValue,
                'text-gray-500 dark:text-gray-400': step.id !== props.modelValue,
                'cursor-pointer': props.clickable && !step.disabled,
                'cursor-not-allowed': step.disabled ?? false
            }"
            @click="clicked(step.id)"
            v-for="(step, index) in props.steps" :key="step.id">
            <span
                class="flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
                :class="{'border-blue-600 dark:border-blue-500': step.id === props.modelValue, 'border-gray-500 dark:border-gray-400': step.id !== props.modelValue}">
                {{ (index + 1).toString() }}
            </span>
            <span>
                <h3 class="font-medium leading-tight">{{ step.title }}</h3>
                <p class="text-sm" v-if="step.text">{{ step.text }}</p>
            </span>
        </li>
    </ol>
</template>

<script setup lang="ts">

import {StepperStep} from './VStepperTypes';

const props = withDefaults(defineProps<{
    modelValue?: string | null,
    steps: StepperStep[],
    clickable?: boolean
}>(), {
    modelValue: null,
    clickable: false
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string|null): void
}>();

const clicked = (id: string) => {
    if (props.clickable && props.steps.find(step => step.id === id)?.disabled !== true) {
        emit('update:modelValue', id);
    }
};


</script>

<style scoped>

</style>
