<template>
    <button
        v-if="!loading"
        @click="$emit('click')"
        :disabled="disabled"
        :type="props.type"
        :class="{['bg-' + props.bgColour]: true, 'p-2': !props.tight}"
        class="rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <slot></slot>
    </button>
    <button
        v-else
        disabled
        :class="{['bg-' + props.bgColour]: true, 'p-2': !props.tight}"
        :type="props.type"
        class="rounded-md p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    >
        <i class="fa fa-spinner fa-spin"></i>
    </button>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
    bgColour?: string;
    loading?: boolean;
    disabled?: boolean;
    tight?: boolean;
    type?: 'button'|'submit'|'reset';
}>(), {
    bgColour: 'white',
    loading: false,
    disabled: false,
    tight: false,
    type: 'button',
});

defineEmits<{
    (event: 'click'): void
}>();
</script>

<style scoped>

</style>
