<template>
    <v-field-wrapper :id="props.id" :hint="hint" :label="label" :error="error" :disabled="disabled">
        <template #default="{bind}">
            <VueDatePicker
                v-bind="bind"
                v-model="dynamicValue"
                :id="props.id"
                :name="props.id"
                format="dd/MM/yyyy HH:mm"
                :start-time="{hours: 8, minutes: 0, seconds: 0}"
                :disabled="props.disabled"
                class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :class="[(pale || props.disabled) ? 'text-gray-400  dark:text-gray-800' : 'text-gray-900  dark:text-white']"
            ></VueDatePicker>
        </template>
    </v-field-wrapper>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';

const props = withDefaults(defineProps<{
    id: string,
    label: string,
    hint?: string|null,
    modelValue?: string | null,
    pale?: boolean | null,
    error?: string|null,
    disabled?: boolean
}>(), {
    pale: false,
    hint: null,
    modelValue: null,
    error: null,
    disabled: false
});

const dynamicValue = computed({
    get() {
        return props.modelValue ? new Date(props.modelValue) : null;
    },
    set(value) {
        if(value) {
            value = moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
        emit('update:modelValue', value);
    }
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string): void
}>();
</script>

<style scoped>

</style>
