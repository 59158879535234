<template>
    <v-sidebar-item :icon="props.icon" :text="props.text">

    </v-sidebar-item>
</template>

<script setup lang="ts">

const props = defineProps<{
    href?: string,
    icon?: string|null;
    text?: string;
}>();

</script>

<style scoped>

</style>
