<template>
    <template v-if="!fetching && arrayWithValues(data)">
        <div 
            v-for="order in data"
            class="flex flex-wrap items-start"
            :key="order.id"
        >
            <button 
                type="button" 
                @click="() => toggleExpanded(order.id)"
                class="mt-5 p-2 rounded-l-md border border-r-0 border-gray-200"
            >
                <i 
                    class="fa-solid w-[22px] text-gray-500" 
                    :class="{ 
                        'fa-chevron-right' : !expanded.includes(order.id),
                        'fa-chevron-down' : expanded.includes(order.id)
                    }"
                    aria-hidden="true" 
                />
                <span class="sr-only">{{ expanded.includes(order.id) ? 'Minify' : 'Expand' }}</span>
            </button>

            <div class="w-[calc(100%-39px)] rounded-lg border border-gray-200 flex flex-wrap divide-y lg:divide-y-0 lg:divide-x bg-white shadow-md mb-5">
                <div 
                    class="w-full rounded-tl-lg rounded-tr-lg lg:rounded-l-lg lg:rounded-tr-none lg:w-1/3 p-4 flex flex-col"
                    :class="{
                        'text-white bg-blue-600': selectMode && selectedOrder && selectedOrder == order.id
                    }"
                >
                    <VBoxOrder 
                        :order="order" 
                        :minified="!expanded.includes(order.id)"
                        :selected="selectMode && selectedOrder == order.id"
                        :selectOrder="selectOrder"
                        :selectMode="selectMode"
                    />
                </div>

                <div class="w-full bg-gray-50 rounded-b-lg lg:rounded-bl-none lg:rounded-tr-lg lg:w-2/3 flex flex-wrap divide-y lg:divide-y-0 lg:divide-x">
                    <div class="w-full lg:w-1/2 p-4" v-if="arrayWithValues(order.dispatches) || arrayWithValues(order.dispatch_requests)">
                        <h3 class="text-gray-900 font-semibold mb-3">Dispatches</h3>
                        <template v-if="arrayWithValues(order.dispatches)">
                            <VBoxDispatch 
                                v-for="dispatch in order.dispatches" 
                                :dispatch="dispatch" 
                                :minified="!expanded.includes(order.id)"
                                :selectMode="selectMode"
                                :selected="selectMode && selectedDispatch == dispatch.id"
                                :selectDispatch="selectDispatch"
                                :canBeSelected="selectedOrder === undefined || selectedOrder === null || selectedOrder === order.id"
                                :key="dispatch.id"
                            />
                        </template>
                        
                        <VBoxDispatchRequest
                            v-for="request in filterDispatchRequests(order)"
                            :request="request"
                            :minified="!expanded.includes(order.id)"
                            :key="request.id"
                        />
                    </div>

                    <div class="w-full lg:w-1/2 p-4" v-if="arrayWithValues(order.returns)">
                        <h3 class="text-gray-900 font-semibold mb-3">Returns</h3>
                        <VBoxReturn 
                            v-for="returnModel in order.returns" 
                            :returnModel="returnModel" 
                            :minified="!expanded.includes(order.id)"
                            :key="returnModel.id"
                        />
                    </div>
                </div>
            </div>
        </div>
    </template>
    <div v-if="fetching" class="w-full p-8">
        <VBoxOrderViewerFetching />
    </div>
    <div class="w-full p-8" v-if="!fetching && !arrayWithValues(data)">
        <VStatusCard
            heading="No Results"
            text="No orders found." 
        />
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { ref } from 'vue';

/**
 * Local dependencies
 */
import { arrayWithValues } from '../../Helpers/array-utils';
import { GetOrderIndexResponse, Order } from './Types/orders';
import { Dispatch } from './Types/dispatches';

/**
 * External dependencies
 */
import get from 'lodash/get';

/**
 * Props
 */
const props = withDefaults(defineProps<{
    data?: GetOrderIndexResponse['data'];
    fetching?: boolean;
    selectMode?: boolean;
}>(), {
    fetching: true,
    selectMode: false
});
const selectedOrder = defineModel<Order['id']|null>('selectedOrder');
const selectedDispatch = defineModel<Dispatch['id']|null>('selectedDispatch');
const expanded = ref<number[]>([]);

// Functions
/**
 * Toggle Expanded
 * 
 * Function that adds or removes an element from the array of 'expanded' views.
 * 
 * @param {number} orderId The order id of the element to expand or contract.
 */
const toggleExpanded = (orderId: number) => {
    const match = expanded.value.indexOf(orderId);
    const newValue = [...expanded.value];

    (match !== -1) ? newValue.splice(match, 1) : newValue.push(orderId);

    expanded.value = newValue;
};

/**
 * Select Order
 * 
 * When the component is in 'select' mode, assigns the orderId to the passed v-model
 * value when clicked.
 * 
 * @param {number|undefined} orderId The orderId to select or deselect as the 'selected' order.
 */
const selectOrder = (orderId: number|undefined) => {
    if (selectedOrder.value === undefined || selectedOrder.value === null || selectedOrder.value !== orderId) {
        selectedOrder.value = orderId;

        // Deselect selected dispatches if not a child of this order
        if (arrayWithValues(props.data)) {
            const orderRecord = props.data.find(order => order.id === orderId);
            const dispatchParentOrderRecord = props.data.find(order => {
                if (arrayWithValues(order.dispatches)) {
                    const matchingDispatch = order.dispatches.find(dispatch => {
                        return dispatch.id === selectedDispatch.value && dispatch.order_id === order.id;
                    });

                    return matchingDispatch ? true : false;
                } else {
                    return false;
                }
            });

            if (dispatchParentOrderRecord && dispatchParentOrderRecord.id !== orderId) {
                selectedDispatch.value = undefined;
            }

            // Auto-select child dispatch if only one to choose from
            if(orderRecord && arrayWithValues(orderRecord.dispatches) && orderRecord.dispatches.length === 1) {
                selectedDispatch.value = orderRecord.dispatches[0].id;
            }
        }
    } else {
        selectedOrder.value = undefined;
        selectedDispatch.value = undefined;
    }
};

/**
 * Select Dispatch
 * 
 * When a component is in 'select' mode, assigns the dispatchId to the passed v-model
 * value when clicked.
 * 
 * @param {number|undefined} dispatchId The dispatchId to select or deselect as the 'selected'
 *                                      dispatch.
 */
const selectDispatch = (dispatchId: number) => {
    if (selectedDispatch.value === undefined || selectedDispatch.value === null || selectedDispatch.value !== dispatchId) {
        selectedDispatch.value = dispatchId;
        
        // Auto select order if not already selected
        if (arrayWithValues(props.data)) {
            const orderRecord = props.data.find(order => {
                if (arrayWithValues(order.dispatches)) {
                    const matchingDispatch = order.dispatches.find(dispatch => {
                        return dispatch.id === dispatchId && dispatch.order_id === order.id;
                    });

                    return matchingDispatch ? true : false;
                } else {
                    return false;
                }
            });

            if (orderRecord) {
                selectedOrder.value = orderRecord.id;
            }
        } 
    } else {
        selectedDispatch.value = undefined;
    }
};

/**
 * Filter dispatch requests
 * 
 * Filters the dispatch requests (if there are any) and strips out any that have an associated
 * dispatch so they are not shown.
 */
const filterDispatchRequests = (order: Order) => {
    let filtered: Order['dispatch_requests'] = get(order, 'dispatch_requests', []);

    if (arrayWithValues(order.dispatches) && arrayWithValues(order.dispatch_requests)) {
        filtered = order.dispatch_requests.filter(request => {
            const match = order.dispatches.find(dispatch => {
                if (arrayWithValues(request.dispatches)) {
                    const dispatchMatch = request.dispatches.find(item => {
                        return item.id === dispatch.id;
                    });
                    
                    return dispatchMatch ? true : false;
                } else {
                    return false;
                }
            });

            return match ? false : true;
        });
    }

    return filtered;
};
</script>
