<template>
    <form-section v-for="step in nonHiddenSteps" v-model:should-show="step.expanded" :key="step.id"
                  :title="step.title"
                  :errors="step.errors">
        <template #icon>
            <span v-if="step.isCalculatingComplete">
                <i class="fa fa-spinner text-orange fa-spin"></i>
            </span>
            <span v-if="step.errors && Object.keys(step.errors).length > 0">
                <i class="fa fa-times text-red"></i>
            </span>
            <span v-else-if="step.isComplete">
                <i class="fa fa-check text-green"></i>
            </span>
        </template>
        <slot :name="'page(' + step.id + ')'" ></slot>
    </form-section>

</template>

<script lang="ts" setup>

import FormSection from './FormSection.vue';
import {computed, ComputedRef, Ref, watch} from 'vue';
import {Errors} from '../../../../core/orders/resources/js/components/NewOrder.vue';

export interface SectionStepperStep {
    id: string;
    title: string;
    isComplete?: ComputedRef<boolean> | Ref<boolean>;
    hidden?: ComputedRef<boolean> | Ref<boolean>;
    expanded?: boolean;
    isCalculatingComplete?: ComputedRef<boolean> | Ref<boolean>;
    errors: ComputedRef<Errors> | Ref<Errors>
}

const steps = defineModel<SectionStepperStep[]>('steps');

const nonHiddenSteps = computed<SectionStepperStep[]>(() => {
    return steps.value?.filter(step => !step.hidden) ?? [];
});

watch(nonHiddenSteps, (steps) => {
    // Iterate through each step. If a step is complete, and the NEXT step is hidden, show the next step
    // except for when any previous step has not been completed
    steps.forEach((step, index) => {
        // If we're at the last step, return
        if(steps.length === index + 1) {
            return;
        }

        // Iterate through all the steps up to this step. If any step is not complete, return
        for(let i = 0; i < index; i++) {
            if(!steps[i].isComplete) {
                return;
            }
        }

        // Find and activate the next step
        let nextStep: SectionStepperStep = steps[index + 1];

        if(step.isComplete && !nextStep.expanded && !nextStep.isComplete) {
            nextStep.expanded = true;
        }
    });
}, {
    deep: true
});

</script>

<style scoped>

</style>
