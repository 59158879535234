<template>
    <v-tooltip :active="props.alt" :text="props.alt">
        <template #activator>
            <div role="status" class="h-full" :class="{'pr-2': badge}">
                <slot>
                    <span>
                        <i class="fa w-8 h-8 pt-1" :class="iconClasses"></i>
                        <span v-if="badge"
                              class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                            {{ badge }}
                        </span>
                    </span>
                </slot>
                <span class="sr-only">{{ alt ?? '' }}</span>
            </div>
        </template>
    </v-tooltip>


</template>

<script lang="ts" setup>

import {computed} from 'vue';

const props = withDefaults(defineProps<{
    alt?: string | null;
    faIcon?: string | null;
    badge?: string | number | null;
    colour?: string | null;
    extraIconClasses?: string | null;
}>(), {
    alt: null,
    faIcon: null,
    badge: null,
    colour: null,
    extraIconClasses: null
});

const iconClasses = computed<string>(() => {
    let classes = '';
    if (props.faIcon !== null && props.faIcon !== undefined) {
        classes += `fa-${props.faIcon} `;
    }
    if(props.colour !== null && props.colour !== undefined) {
        classes += `text-${props.colour}`;
    }
    if(props.extraIconClasses) {
        classes += ' ' + props.extraIconClasses;
    }
    return classes;
});

</script>

<style scoped>

</style>
