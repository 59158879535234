<template>
    <div class="w-full p-3 border border-gray-200 bg-white rounded-lg mb-3">
        <div class="flex gap-x-4 justify-between items-start border-b border-gray-200 pb-4">
            <div class="flex flex-col items-start justify-start">
                <h3 class="text-gray-900 font-semibold">{{ `RTN-${returnModel.id}` }}</h3>
                <time 
                    class="text-xs text-gray-400" 
                    :datetime="returnModel.created_at"
                >
                    {{ moment(returnModel.created_at).format('DD/MM/YYYY @ HH:mm') }}
                </time>
            </div>
            <VLozenge :variant="calculateReturnStatusLozengeVariant(returnModel.status)">{{ startCase(returnModel.status) }}</VLozenge>
        </div>

        <dl class="divide-y divide-gray-200 text-sm">
            <div class="flex justify-between gap-x-4 py-4" v-if="arrayWithValues(returnModel.items)">
                <dt class="text-gray-400 w-1/3">Returned Items</dt>
                <dd class="text-gray-900 w-2/3 flex flex-wrap leading-tight">
                    <span 
                        class="text-gray-900 w-full block text-right" 
                        v-for="item in returnModel.items"
                    >
                        {{ `${item.quantity} x ${item.sku}` }}
                    </span>
                </dd>
            </div>

            <div class="flex justify-between gap-x-4 py-4" v-if="!minified && returnModel.reason_label">
                <dt class="text-gray-400 w-1/3">Return Reason</dt>
                <dd class="text-gray-900 w-2/3 flex flex-wrap leading-tight">
                    <span class="text-gray-900 w-full block text-right">
                        {{ returnModel.reason_label }}
                    </span>
                </dd>
            </div>
        </dl>

        <div class="rounded-b-xl border-t border-gray-200 flex divide-x justify-center -ml-3 -mr-3 -mb-3 mt-auto" v-if="!minified">
            <a :href="`/tools/returns/returns/${returnModel.id}`" class="inline-flex items-center justify-center gap-x-2 py-2 font-semibold grow">
                <i class="fa-regular fa-eye w-5 text-gray-400" aria-hidden="true"></i>
                <span>VIEW FULL RETURN</span>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * External dependencies
 */
import startCase from 'lodash/startCase';
import moment from 'moment';

/**
 * Local dependencies
 */
import { LozengeProps } from '../Lozenge/props';
import { arrayWithValues } from '../../Helpers/array-utils';
import { Returns, ReturnStatus } from '../../../../tools/returns/resources/js/types/returns';

withDefaults(defineProps<{
    returnModel: Returns;
    minified?: boolean;
}>(), {
    minified: true
});

// Functions

/**
 * Calculate Return Status Lozenge Variant
 * 
 * Translates the current return status into a variant that defines the colour and styling of the
 * status lozenge shown.
 * 
 * @param {string} returnStatus The return status to translate into a lozenge variant.
 */
const calculateReturnStatusLozengeVariant = (returnStatus: ReturnStatus): LozengeProps['variant'] => {
    switch (returnStatus) { 
    case 'cancelled':
        return 'error';
    case 'given_to_customer':
    case 'processing':
    case 'arrived':
        return 'info';
    case 'awaiting_refund':
        return 'warning';
    case 'complete':
        return 'success';
    case 'created':
    case 'created_unexpected':
    default:
        return 'default';
    }
};
</script>
