<template>
    <div class="flex justify-between mb-10">
        <v-stepper v-model="dynamicModel" :steps="stepsWithDisabledIfPreviousNotValid" :clickable="props.clickable">
        </v-stepper>
    </div>


    <div class="flex flex-row pb-6 justify-between">
        <div>
            <v-button v-if="previousStep !== null" :disabled="previousStep === null || previousStep.disabled === true" @click="goToPreviousStep">
                Previous
            </v-button>
        </div>
        <div v-if="nextStep === null">
            <slot name="finalNextButton">

            </slot>
        </div>
        <div v-else>
            <v-button @click="proceedToNextStep" :disabled="nextStep === null || !currentStep.isValid || nextStep.disabled">
                Next
            </v-button>
        </div>
    </div>

    <div v-for="step in steps">
        <div v-show="step.id === dynamicModel" class="mb-10">
            <slot :name="'page(' + step.id + ')'"></slot>
        </div>
    </div>

    <div class="flex flex-row pt-6 pb-15 justify-between">
        <div>
            <v-button v-if="previousStep !== null" :disabled="previousStep === null || previousStep.disabled === true" @click="goToPreviousStep">
                Previous
            </v-button>
        </div>
        <div v-if="nextStep === null">
            <slot name="finalNextButton">

            </slot>
        </div>
        <div v-else>
            <v-button @click="proceedToNextStep" :disabled="nextStep === null || !currentStep.isValid || nextStep.disabled">
                Next
            </v-button>
        </div>
    </div>

</template>

<script setup lang="ts">

import {computed} from 'vue';
import {StepperStep} from './VStepperTypes';

const props = withDefaults(defineProps<{
    modelValue?: string | null,
    steps: StepperStep[],
    clickable?: boolean
}>(), {
    modelValue: null,
    clickable: false
});

const dynamicModel = computed<string | null>({
    get: () => props.modelValue,
    set: (val: string|null) => {
        emit('update:modelValue', val);
        let step = props.steps.find(step => step.id === val);
        if(step?.onOpen) {
            step?.onOpen();
        }
    }
});

const stepsWithDisabledIfPreviousNotValid = computed<StepperStep[]>(() => {
    return props.steps.map((step, index) => {
        if(index === 0) {
            return step;
        }
        const previousStep = props.steps[index - 1];
        return {
            ...step,
            disabled: step.disabled || !previousStep.isValid
        };
    });
});

const currentStep = computed<StepperStep>(() => {
    let step = props.steps.find(step => step.id === dynamicModel.value);

    if (!step) {
        step = props.steps[0];
    }

    if(!step) {
        throw new Error('No steps found');
    }

    return step;
});

const previousStep = computed<StepperStep|null>(() => {
    const currentIndex = props.steps.findIndex(step => step.id === currentStep.value.id);
    if(currentIndex === 0) {
        return null;
    }
    return props.steps[currentIndex - 1];
});

const nextStep = computed<StepperStep|null>(() => {
    const currentIndex = props.steps.findIndex(step => step.id === currentStep.value.id);
    if(currentIndex === props.steps.length - 1) {
        return null;
    }
    return props.steps[currentIndex + 1];
});

const goToPreviousStep = () => {
    if(previousStep.value) {
        dynamicModel.value = previousStep.value.id;
    }
};

const proceedToNextStep = () => {
    if(nextStep.value) {
        dynamicModel.value = nextStep.value.id;
    }
};

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string|null): void
}>();



</script>

<style scoped>

</style>
