<template>
    <v-tooltip :text="humanReadableDate" v-if="humanReadableDate !== null" class="relative">
        <template #activator>
            <span>
                {{formattedDate}}
            </span>
        </template>
    </v-tooltip>

    <span>
        <span ref="mainSlotRef" class="hidden">
            <slot>
            </slot>
        </span>
    </span>

</template>

<script setup lang="ts">

import {computed, ref, watch} from 'vue';
import VTooltip from '../Tooltip/VTooltip.vue';
import moment from 'moment';

const props = withDefaults(defineProps<{
    date?: string|null;
    showTime?: boolean;
    showSeconds?: boolean;
}>(), {
    date: null,
    showTime: false,
    showSeconds: false
});

const formattedDate = computed<string | null>(() => {
    if(dateEvaluated.value === null) {
        return null;
    }
    let date = moment(dateEvaluated.value);
    return date.format('DD/MM/YYYY' + (props.showTime ? ' HH:mm' : '') + (props.showTime && props.showSeconds ? ':ss' : ''));
});

const humanReadableDate = computed<string|null>(() => {
    if(dateEvaluated.value === null) {
        return null;
    }
    let currentDate = new Date();
    let date = moment(dateEvaluated.value);
    return moment.duration(date.diff(moment(currentDate))).humanize() + ' ' + (date.isAfter(moment(currentDate)) ? 'from now' : 'ago');
    // return moment.duration(moment().diff( moment(dateEvaluated.value))).humanize() + ' ago';
});

const mainSlotRef = ref<HTMLElement|null>(null);
const mainSlotText = ref<string|null>(null);

function extractTextFromElement(htmlElement: HTMLElement|null): string {
    if(htmlElement === null) {
        return '';
    }
    if(htmlElement.firstElementChild !== null) {
        return extractTextFromElement(htmlElement.firstElementChild as HTMLElement);
    }
    return htmlElement.innerText === '' ? htmlElement.innerHTML : htmlElement.innerText;
}

watch(mainSlotRef, (newValue) => {
    mainSlotText.value = extractTextFromElement(newValue);
}, {immediate: true});

const dateEvaluated = computed<string|null>(() => {
    if(props.date !== null ) {
        return props.date;
    } else if(mainSlotText.value) {
        return mainSlotText.value;
    }

    return null;
});


</script>

<style scoped>

</style>
