<template>
    <div class="max-w-sm px-4 pt-4 pb-2 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col"
         :class="{'bg-gray-200': disabled, 'bg-white': !disabled}">
        <div class="grow">
            <a :href="href ?? '#'" class="flex flex-row items-center">
                <i v-if="icon" :class="'fa fa-' + icon + ' text-gray-900'"></i>
                <h5 class="ml-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ title }}</h5>
            </a>
            <p
                v-if="subtitle"
                class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                {{subtitle }}
            </p>
            <slot>
                <p
                    v-if="text"
                    class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {{text }}
                </p>
            </slot>

            <div class="w-full text-center">
                <v-button
                    class="inline-flex items-center px-3 py-2 text-center text-sm font-medium"
                    v-if="href && showButton"
                    :disabled="disabled"
                    :href="href ?? '#'">
                    <span class="flex flex-row">
                        {{ props.linkText ?? 'Read more' }}
                        <svg class="w-3.5 h-3.5 ml-2 my-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </span>
                </v-button>
            </div>
        </div>
        <div class="mb-0 w-full mt-4">
            <slot name="footer">

            </slot>
        </div>
    </div>

</template>

<script setup lang="ts">

import VButton from '../Button/VButton.vue';

const props = withDefaults(defineProps<{
    title: string,
    subtitle?: string|null,
    href?: string|null,
    disabled?: boolean,
    text?: string|null,
    linkText?: string|null,
    showButton?: boolean,
    icon?: string|null
}>(), {
    href: null,
    subtitle: null,
    disabled: false,
    text: null,
    linkText: 'Read more',
    showButton: true,
    icon: null
});

</script>

<style scoped>

</style>
