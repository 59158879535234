import {defineStore} from 'pinia';
import {ref} from 'vue';
import axios from 'axios';
import {route} from 'ziggy-js';

export interface CountryOption {
    id: number,
    iso: string,
    name: string
}

export const useCountryStore = defineStore('country', () => {
    const countries = ref<CountryOption[]>([]);
    const loading = ref<boolean>(false);

    function loadCountries() {
        if(loading.value === false && countries.value.length === 0) {
            loading.value = true;
            axios.get(route('api.country.index', {
            }))
                .then(response => {
                    countries.value = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    }

    return {countries, loading, loadCountries};
});


