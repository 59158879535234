<template>
    <li>
        <div  class="w-full flex items-center text-left p-2 group rounded-md"
              :class="{
                  'text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700': !selected,
                  'bg-blue-300 text-white-900 dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700': selected,
                  'bg-pink-200': !selected && props.colour === 'pink' && shouldBeColoured,
                  'bg-red-200': !selected && props.colour === 'red' && shouldBeColoured,
                  'bg-orange-200': !selected && props.colour === 'orange' && shouldBeColoured,
                  'bg-brown-200': !selected && props.colour === 'brown' && shouldBeColoured,
                  'bg-blue-grey-200': !selected && props.colour === 'blue-grey' && shouldBeColoured,
                  'bg-yellow-200': !selected && props.colour === 'yellow' && shouldBeColoured,
                  'bg-green-200': !selected && props.colour === 'green' && shouldBeColoured,
                  'bg-purple-200': !selected && props.colour === 'purple' && shouldBeColoured,
                  'bg-beige': !selected && props.colour === 'beige' && shouldBeColoured,
              }">
            <a :href="props.href ?? '#'" class="flex flex-row w-full">
                <button :type="props.submitButton ? 'submit' : 'button'" class="grow text-left">
                    <i v-if="props.icon" class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white fa" :class="{['fa-' + props.icon]: true}"></i>
                    <span class="flex-1 ms-3 whitespace-nowrap">{{ text }}</span>
                </button>
            </a>
            <slot name="badge"></slot>
        </div>

    </li>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import {setting} from '../../Tools/settings/settings';

const props = defineProps<{
    selected?: boolean,
    href?: string,
    colour?: 'pink' | 'red' | 'orange' | 'brown' | 'blue-grey' | 'yellow' | 'green' | 'purple' | 'beige',
    icon?: string|null;
    text?: string;
    submitButton?: boolean
}>();

const shouldBeColoured = computed<boolean>(() => {
    return setting.value['accessibility_coloured_sidebar'] ?? false;
});

</script>

<style scoped>

</style>
