<template>
    <div class="flex flex-row w-full sm:min-w-64">
        <div class="flex flex-row w-full">
            <div class="flex flex-col grow">
                <v-select :searchable="true"
                          v-model="dynamicSku"
                          :options="skuOptions"
                          :label="props.label"
                          :search-function="loadSkus"
                          :hint="props.hint"
                          :filterable="false"
                          :error="props.error"
                          :multiple="props.multiple"
                          :id="props.id">

                </v-select>
            </div>

            <v-modal ref="modal"
                     class="text-left"
                     v-if="showKitCreator"
                     header="Kit Configurator">
                <template #activator="{show}">
                    <v-tooltip text="Add a kit" class="h-full flex">
                        <template #activator>
                            <v-icon-button @click="show" :tight="true" class="mx-1">
                                <i class="fa fa-object-group mx-1"></i>
                            </v-icon-button>
                        </template>
                    </v-tooltip>
                    <!--                    <v-button class="mr-2" colour="secondary" type="button" @click="show">-->
                    <!--                        Add Kit-->
                    <!--                    </v-button>-->
                </template>

                <div class="text-left px-10 py-4">
                    <version-three-kit-builder v-model="newKitSku"></version-three-kit-builder>
                </div>

                <template #footer>
                    <v-button @click="createKit">
                        <span v-if="dynamicSku">Update SKU</span>
                        <span v-else>Add kit to order</span>
                    </v-button>
                </template>
            </v-modal>

        </div>

        <!--        <v-modal header="Kit Builder" ref="modal">-->
        <!--            <template #activator="{show}">-->
        <!--                <v-tooltip text="Open Kit Builder" position="left">-->
        <!--                    <template #activator>-->
        <!--                        <v-icon-button @click="show" class="mt-4">-->
        <!--                            <i class="fa fa-hammer px-2"></i>-->
        <!--                        </v-icon-button>-->
        <!--                    </template>-->
        <!--                </v-tooltip>-->
        <!--            </template>-->
        <!--            <div>-->
        <!--            </div>-->
        <!--        </v-modal>-->

        <div class="ml-4 mt-4 h-full flex justify-center items-center" v-if="props.showCost">
            <div class="pb-2 font-bold">${{ skuCost ?? '?' }}</div>
        </div>
    </div>

</template>

<script lang="ts" setup>

import {computed, ref, watch, watchEffect} from 'vue';
import VSelect from '../Select/VSelect.vue';
import {SelectOption} from '../../Types/forms';
import {debounce} from 'lodash';
import axios from 'axios';
import { route } from 'ziggy-js';
import VModal from '../Modal/VModal.vue';
import VersionThreeKitBuilder from '../../../../tools/skus/resources/js/Components/VersionThreeKitBuilder.vue';
import VIconButton from '../IconButton/VIconButton.vue';
import VTooltip from '../Tooltip/VTooltip.vue';

interface SkuOption {
    sku: string,
    cost_value_usd: number,
    name: string,
    sku_type: string
}

const props = withDefaults(
    defineProps<{
        showKitCreator: boolean,
        onlyDispatchableSkus: boolean,
        label: string,
        id: string,
        modelValue: string|null|string[],
        showCost: boolean,
        costMultiplier: number,
        hint?: string,
        error?: string,
        multiple?: boolean
  }>(), {
        showKitCreator: false,
        onlyDispatchableSkus: false,
        modelValue: null,
        showCost: false,
        costMultiplier: 1,
        hint: null,
        error: null,
        multiple: false
    }
);

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string|null): void
}>();

const skus = ref<SkuOption[]>([]);

const calculateSkuOptionsFromSkus = (skus: SkuOption[]): SelectOption[] => {
    let groupedSkus = skus.reduce((acc, sku) => {
        if(!acc[sku.sku_type]) {
            acc[sku.sku_type] = [];
        }
        acc[sku.sku_type].push(sku);
        return acc;
    }, {} as Record<string, SkuOption[]>);

    let options: SelectOption[] = [];

    for (let skuType in groupedSkus) {
        options.push({
            label: skuType,
            heading: true,
            value: null,
            disabled: true,
        });
        for(let sku of groupedSkus[skuType]) {
            options.push({
                label: sku.sku + ' (' + sku.name + ')',
                value: sku.sku
            });
        }
    }

    return options;
};

const skuOptions = computed<SelectOption[]>(() => {
    return calculateSkuOptionsFromSkus(skus.value);
});

const dynamicSku = computed<string|null>({
    get() {
        return props.modelValue ?? null;
    },
    set(value: string|null) {
        emit('update:modelValue', value);
    }
});

const searchQuery = ref<string|null>(null);

watch(searchQuery, debounce(() => {
    if(searchQuery.value !== null) {
        loadSkus(searchQuery.value);
    }
}, 500));

const loadingSkus = ref<boolean>(false);

const loadSkus = (query: string | null , loadAll: boolean = false): Promise<SelectOption[]> => {
    if(query === null) {
        return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
        axios.get(route('api.tools.skus.skus.index', {
            query: query,
            // Load all SKUs if loadAll is true, otherwise default to the toggle
            onlyDispatchable: loadAll ? false : props.onlyDispatchableSkus
        }))
            .then(response => {
                skus.value = response.data.skus;
                resolve(calculateSkuOptionsFromSkus(response.data.skus));
            })
            .catch(error => {
                reject(error);
            })
            .finally(() => {
                loadingSkus.value = false;
            });
    });
};

const skuCost = computed<number|null>(() => {
    if(props.modelValue === null) {
        return null;
    }
    let cost = skus.value.find(s => s.sku === props.modelValue)?.cost_value_usd ?? null;
    if(cost !== null) {
        return cost * props.costMultiplier;
    }
    return null;
});

watchEffect(() => {
    if(props.modelValue !== null) {
        if(Array.isArray(props.modelValue)) {
            loadSkus(props.modelValue.join(','), true);
        } else {
            loadSkus(props.modelValue, true);
        }
    }
});

const newKitSku = ref<string|null>(null);

const modal = ref<VModal | null>(null);

const createKit = () => {
    if(newKitSku.value) {
        dynamicSku.value = newKitSku.value;
        modal.value?.close();
    }
};

</script>

<style scoped>

</style>
