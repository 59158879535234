<template>
    <div class="w-full flex flex-col mt-2">
        <span class="p-1 pl-4 font-bold bg-grey-200 rounded-lg w-full cursor-pointer" @click="shouldShow = !shouldShow">
            <slot name="icon"></slot>
            {{ props.title }}</span>
        <Transition name="slide-fade">
            <div v-show="shouldShow" class="ml-4">
                <div v-if="Object.keys(props.errors ?? {}).length > 0" class="mt-2">
                    <v-alert colour="red" class="mt-2">
                        <strong>There were some errors with your submission</strong>
                        <ul v-for="(error, key) in props.errors">
                            <li><span class="font-bold">{{ key }}:</span>
                                <span v-if="Array.isArray(error)">
                                    <ul>
                                        <li v-for="subError in error">{{ subError }}</li>
                                    </ul>
                                </span>
                                <span v-else>
                                    <ul>
                                        <li v-for="(subError, s) in error"><span class="font-bold">{{s}}:</span> {{ subError.join(', ') }}</li>
                                    </ul>
                                </span>
                            </li>
                        </ul>
                    </v-alert>
                </div>
                <slot></slot>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>

import {Errors} from '../../../../core/orders/resources/js/components/NewOrder.vue';

const shouldShow = defineModel<boolean>('shouldShow', {
    default: false,
});

const props = defineProps<{
    title: string,
    errors?: Errors,
}>();

</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>