<template>
    <h3 class="font-semibold text-gray-900 dark:text-white">{{ label }}</h3>
    <slot name="prepend"></slot>
    <ul class="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        :class="{'w-48': !horizontal, 'items-center w-full sm:flex': horizontal}"
        v-if="options && !loading">
        <li class="w-full border-b border-gray-200 dark:border-gray-600" :class="{'sm:border-b-0 sm:border-r': horizontal, 'rounded-t-lg': !horizontal}" v-for="option in options" :key="option.id">
            <div class="flex items-center pl-3">
                <input :id="props.id + '-' + option.id" type="radio" :value="option.value" v-model="dynamicValue"
                       :name="props.id"
                       :aria-describedby="props.id + '-' + option.id + '-hint'"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <label :for="props.id + '-' + option.id" class="w-full py-3 ml-2 mr-2 flex flex-col justify-start content-center">
                    <span
                        class="text-sm font-medium text-gray-900 dark:text-gray-300">{{option.label}}</span>
                    <span v-if="option.hint" :id="props.id + '-' + option.id + '-hint'" class="text-sm text-gray-500 dark:text-gray-400">
                        {{ option.hint }}
                    </span>
                </label>
            </div>
        </li>
    </ul>
    <div v-else>
        <v-spinner class="w-8 h-8"></v-spinner>
    </div>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import {RadioCardOption} from '../../types/Form';
import VSpinner from '../Spinner/VSpinner.vue';


const props = withDefaults(defineProps<{
    id: string,
    horizontal?: boolean
    options: RadioCardOption[],
    modelValue?: number | string | null,
    label: string,
    loading?: boolean,
    errors?: string[]
}>(), {
    horizontal: false,
    loading: false,
    modelValue: null
});

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: number | string | null): void
}>();
</script>

<style scoped>

</style>
