<template>
    <div v-if="isActive && props.disabled === false" class="w-full">
        <slot></slot>
    </div>
<!--    <div v-else-if="props.disabled">-->
<!--        <div class="w-full flex justify-center text-gray-300">-->
<!--            <i class="fa fa-lock fa-2xl mt-4"></i>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script setup lang="ts">
import {inject, onBeforeMount, ref, watch} from 'vue';
import {VNode} from 'vue/dist/vue';
import {TabProps} from '../../Types/tabs';

const index = ref<number>(0);
const isActive = ref<boolean>(false);

const props = withDefaults(defineProps<{
    tabId?: string; // An ID all tabs should share to link them to the v-tabs parent
    id?: string; // A unique ID for this tab to allow us to load it by URL
    disabled?: boolean;
    icon?: string;
    title?: string;
}>(), {
    tabId: ''
});

const tabs = inject<{
    selectedIndex: number;
    tabs: VNode<TabProps>[];
    count: number;
}>(props.tabId + 'TabsProvider', {
    selectedIndex: 0,
    tabs: [],
    count: 0
});

watch(
    () => tabs.selectedIndex.value,
    () => {
        isActive.value = index.value === tabs.selectedIndex.value;
    }
);

onBeforeMount(() => {
    index.value = tabs.count.value;
    tabs.count.value++;
    isActive.value = index.value === tabs.selectedIndex.value;
});

</script>

<style scoped>

</style>
