<template>
    <div class="bg-white border rounded-lg items-center relative overflow-hidden" :class="{'-order-1' : primaryViewer}">
        <div class="p-4 rounded flex justify-between items-center">
            <div class="flex flex-grow items-center gap-2 cursor-pointer" @click="toggleShowData">
                <i class="fa" :class="'fa-' + icon"></i>
                <h4 class="text-sm text-blue-500" :class="{'font-bold': primaryViewer, 'font-medium': !primaryViewer}">
                    {{ title }}</h4>
            </div>
            <div class="flex flex-row items-center gap-2 justify-right">
                <div class="flex flex-row">
                    <slot name="header-actions">

                    </slot>
                </div>
                <v-icon-button :loading="false" @click="toggleShowData">
                    <i class="fa p-2" :class="{'fa-angle-right': !props.modelValue, 'fa-angle-down': props.modelValue}" v-if="enabled"/>
                </v-icon-button>
            </div>
        </div>
        <div class="transition ease-in ease-out bg-inherit border-l flex flex-col">
            <div v-if="props.modelValue">
                <hr class="h-0.5 mx-4 my-0 bg-gray-200 border-0 rounded dark:bg-gray-700">
            </div>

            <slot name="actions" v-if="props.modelValue || enabled === false">

            </slot>

            <div v-show="props.modelValue || enabled === false">
                <slot name="default">

                </slot>
            </div>
            <slot name="always-expanded">

            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: boolean): void
}>();

const props = withDefaults(defineProps<{
    modelValue: boolean;
    icon?: string | null;
    title: string;
    enabled?: boolean;
    primaryViewer?: boolean | null;
    rawData?: string|null;
}>(), {
    icon: null,
    enabled: true,
    primaryViewer: false,
    rawData: null,
});

// const showData = ref<boolean>(props.modelValue);

const toggleShowData = () => {
    emit('update:modelValue', !props.modelValue);
    // showData.value = !showData.value;
};

// watch(showData, (val) => {
//     emit('update:modelValue', val);
// })

</script>

<style scoped>

</style>
