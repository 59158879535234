import {ref, watch} from 'vue';

const sidebarShowing = ref<boolean>(window.localStorage.getItem('sidebarVisible') !== 'false');

watch(() => sidebarShowing.value, () => {
    initialise();
});

const initialise = () => {
    setLocalStorage();
    setContentMargin();

};

window.onbeforeunload = function() {
    const smallScreen = window.matchMedia('(max-width: 600px)').matches;
    if (smallScreen) {
        sidebarShowing.value = false;
    }
};

const setLocalStorage = () => {
    if(sidebarShowing.value) {
        window.localStorage.setItem('sidebarVisible', 'true');
    } else {
        window.localStorage.setItem('sidebarVisible', 'false');
    }
};

const setContentMargin = () => {
    const smallScreen = window.matchMedia('(max-width: 600px)').matches;
    if (sidebarShowing.value && !smallScreen) {
        document.getElementById('sidebar-remaining-content')?.classList.add('pl-64');
    } else {
        document.getElementById('sidebar-remaining-content')?.classList.remove('pl-64', 'sm:pl-64');
    }
};

export {sidebarShowing, initialise};
