<template>
    <v-modal :header="props.title" ref="modal">
        <div class="text-center px-4">
            <slot></slot>
        </div>

        <template #footer="{close}">
            <v-button @click="close" class="mr-1">
                {{backButtonText}}
            </v-button>
            <v-button @click="confirm">
                {{confirmButtonText}}
            </v-button>
        </template>
    </v-modal>

</template>

<script setup lang="ts">

import VButton from '../Button/VButton.vue';
import VModal from '../Modal/VModal.vue';
import {ref} from 'vue';

const props = withDefaults(defineProps<{
    title: string,
    backButtonText?: string,
    confirmButtonText?: string,
}>(), {
    title: 'Are you sure?',
    backButtonText: 'Go back',
    confirmButtonText: 'Confirm'
});

const modal = ref<InstanceType<typeof VModal>|null>(null);

const emit = defineEmits<{
    (event: 'confirm'): void;
}>();

const show = () => {
    modal.value?.show();
};

const confirm = () => {
    emit('confirm');
};

const hide = () => {
    modal.value?.close();
};

defineExpose({
    show,
    hide
});

</script>

<style scoped>

</style>
