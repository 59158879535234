/**
 * Prints a console error when running in development mode.
 * 
 * @param {string} error The error message to log.
 */
const consoleDevError = (error: any) => {
    if (process.env.NODE_ENV === 'development') {
        console.error(error);
    }
};

/**
 * Prints a console log when running in development mode.
 * 
 * @param {any} error The error message to log.
 */
const consoleDevLog = (log: any) => {
    if(process.env.NODE_ENV === 'development') {
        console.log(log);
    }
};

export {
    consoleDevError,
    consoleDevLog
};
