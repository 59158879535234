<template>
    <div 
        class="w-full p-3 border border-gray-200 rounded-lg mb-3"
        :class="{
            'text-white bg-blue-600': selected,
            'bg-white': !selected
        }"
    >
        <div class="flex gap-x-4 justify-between items-start border-b border-gray-200 pb-4">
            <div class="flex flex-col items-start justify-start">
                <h3 
                    class="font-semibold text-md"
                    :class="{
                        'text-white': selected,
                        'text-gray-900': !selected
                    }"
                >
                    {{ `Dispatch #${dispatch.reference}` }}
                </h3>
                <time 
                    class="text-xs"
                    :class="{
                        'text-white': selected,
                        'text-gray-400': !selected
                    }"
                    :datetime="dispatch.created_at"
                >
                    {{ moment(dispatch.created_at).format('DD/MM/YYYY @ HH:mm') }}
                </time>
            </div>
            <VLozenge :variant="calculateDispatchStatusLozengeVariant(dispatch.status)">{{ startCase(dispatch.status) }}</VLozenge>
        </div>

        <dl class="divide-y divide-gray-200 text-sm">
            <div class="flex justify-between gap-x-4 py-4" v-if="arrayWithValues(dispatch.dispatch_items)">
                <dt 
                    class="w-1/3"
                    :class="{
                        'text-white': selected,
                        'text-gray-400': !selected
                    }"
                >
                    Dispatched Items
                </dt>
                <dd 
                    class="w-2/3 flex flex-wrap leading-tight"
                    :class="{
                        'text-white': selected,
                        'text-gray-900': !selected
                    }"
                >
                    <span 
                        class="w-full block text-right" 
                        v-for="item in dispatch.dispatch_items"
                    >
                        {{ `${item.quantity} x ${item.sku}` }}
                    </span>
                </dd>
            </div>

            <div class="flex justify-between gap-x-4 py-4" v-if="!minified">
                <dt 
                    class="w-1/3"
                    :class="{
                        'text-white': selected,
                        'text-gray-400': !selected
                    }"
                >
                    ID
                </dt>
                <dd 
                    class="w-2/3 flex flex-wrap leading-tight"
                    :class="{
                        'text-white': selected,
                        'text-gray-900': !selected
                    }"
                >
                    <span class="w-full block text-right">
                        {{ dispatch.id }}
                    </span>
                </dd>
            </div>

            <div class="flex flex-wrap justify-between gap-x-4 py-4" v-if="!minified && dispatch.tracking_code">
                <dt 
                    class="w-1/2"
                    :class="{
                        'text-white': selected,
                        'text-gray-400': !selected
                    }"
                >
                    Tracking #'s
                </dt>
                <dd 
                    class="flex flex-col items-end grow"
                    :class="{
                        'text-white': selected,
                        'text-gray-900': !selected
                    }"
                >
                    <span class="flex w-full justify-between items-center gap-x-2">
                        <span 
                            class="text-xs"
                            :class="{
                                'text-white': selected,
                                'text-gray-400': !selected
                            }"
                        >(Original)</span>
                        {{ dispatch.tracking_code }}
                    </span>
                </dd>
            </div>
        </dl>

        <div class="rounded-b-lg border-t border-gray-200 flex divide-x justify-center -mr-4 -ml-4 -mb-4 mt-auto" v-if="!minified && !selectMode">
            <a 
                :href="`/tools/watson#/share?model=dispatch&id=${dispatch.id}&reference=${dispatch.reference}`"
                class="inline-flex items-center justify-center gap-x-2 py-2 font-semibold grow"
                v-if="!selectMode"
            >
                <i class="fa-regular fa-eye w-5 text-gray-400" aria-hidden="true"></i>
                <span>VIEW FULL DISPATCH</span>
            </a>
        </div>

        <div class="rounded-b-lg border-t border-gray-200 flex divide-x justify-center -mr-4 -ml-4 -mb-4 mt-auto" v-if="selectMode">
            <button
                class="inline-flex items-center justify-center gap-x-2 py-2 font-semibold grow disabled:cursor-not-allowed disabled:opacity-50"
                @click="() => selectDispatch(dispatch.id)"
                :disabled="!canBeSelected"
                type="button"
            >
                <i 
                    class="fa-regular fa-eye w-5"
                    :class="{
                        'text-white': selected,
                        'text-gray-400': !selected
                    }" 
                    aria-hidden="true"
                ></i>
                <span>{{ selected ? 'DESELECT DISPATCH' : 'SELECT DISPATCH'}}</span>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * External dependencies
 */
import startCase from 'lodash/startCase';
import moment from 'moment';

/**
 * Local dependencies
 */
import { LozengeProps } from '../Lozenge/props';
import { arrayWithValues } from '../../Helpers/array-utils';
import { Dispatch, DispatchStatus } from './Types/dispatches';
import { consoleDevLog } from '../../Helpers/console-utils';

withDefaults(defineProps<{
    dispatch: Dispatch;
    minified?: boolean;
    selectMode?: boolean;
    selected?: boolean;
    canBeSelected?: boolean;
    selectDispatch: (dispatchId: number) => void
}>(), {
    minified: true,
    selectMode: false,
    selected: false,
    canBeSelected: false,
    selectDispatch: (dispatchId: number) => consoleDevLog(`No dispatch select function passed to VBoxDispatch component. DispatchId ${dispatchId}`)
});

// Functions

/**
 * Calculate Dispatch Status Lozenge Variant
 * 
 * Translates the current dispatch status into a variant that defines the colour and styling of the
 * status lozenge shown.
 * 
 * @param {string} dispatchStatus The dispatch status to translate into a lozenge variant.
 */
const calculateDispatchStatusLozengeVariant = (dispatchStatus: DispatchStatus): LozengeProps['variant'] => {
    switch (dispatchStatus) {
    case 'Dispatched':
    case 'Completed':
    case 'Fulfilled (Assumed Delivered)':
        return 'success';
    case 'Awaiting Dispatch':
    case 'Processing':
    case 'Ready to Fulfill':
    case 'Out for Delivery':
        return 'info';
    case 'Out of Stock':
    case 'Partially in Stock':
    case 'Unknown Stock':
    case 'On Hold':
        return 'warning';
    case 'Cancelled':
    case 'Deleted?':
    case 'Abnormal':
        return 'error';
    case 'Draft':
    default: 
        return 'default';
    }
};
</script>
