<template>
    <v-field-wrapper :id="props.id" :hint="hint" :label="label" :error="error" :disabled="isDisabledState" :hiddenLabel="hiddenLabel">
        <template #default="{bind}">
            <div class="relative">
                <div class="flex flex-col">
                    <input
                        v-bind="bind"
                        type="checkbox"
                        v-model="dynamicValue"
                        :id="props.id"
                        :name="props.id"
                        :disabled="isDisabledState"
                        class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        :class="[(pale || isDisabledState) ? 'text-gray-400  dark:text-gray-800' : 'text-gray-900  dark:text-white']"
                    >
                </div>

                <div class="absolute top-0 start-0 w-full h-full bg-white/[.5] rounded-lg dark:bg-gray-800/[.4]" v-if="props.loading"></div>

                <div class="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2" v-if="props.loading">
                    <div class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </template>
    </v-field-wrapper>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';

const props = withDefaults(defineProps<{
    id: string,
    label?: string,
    hiddenLabel?: boolean,
    hint?: string|null,
    modelValue?: boolean,
    pale?: boolean | null,
    error?: string|null,
    disabled?: boolean,
    suggestions?: number[],
    loading?: boolean,
}>(), {
    hiddenLabel: false,
    pale: false,
    hint: null,
    modelValue: null,
    error: null,
    disabled: false,
    suggestions: [],
    loading: false
});

const isDisabledState = computed<boolean>(() => props.disabled || props.loading);

const dynamicValue = computed<boolean>({
    get() {
        return props.modelValue ?? false;
    },
    set(value: boolean) {
        emit('update:modelValue', value);
    }
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: boolean): void
}>();
</script>

<style scoped>

</style>
