
<template>
    <span class="flex flex-row align-middle">
        <span>{{ props.name }}</span>

        <v-tooltip :text="props.description">
            <template #activator>
                <i class="fa fa-info-circle ml-1"></i>
            </template>
        </v-tooltip>
    </span>
</template>

<script lang="ts" setup>
const props = defineProps<{
    name: string,
    description: string
}>();
</script>

<style scoped>

</style>