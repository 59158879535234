<template>

    <div class="flex flex-col">
        <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li class="mr-2"
                    v-for="(tab, index) in tabs"
                    :key="index"
                    v-show="tab.props.disabled === false || tab.props.disabled === undefined || tab.props.disabled === null"
                    @click="selectTab(index)">
                    <a v-if="tab.props.disabled" href="#"
                       class="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">
                        {{ tab.props.title }}
                    </a>
                    <a v-else href="#"
                       class="inline-flex items-center justify-center p-4 border-b-2 group"
                       :class="{'border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': selectedIndex !== index,
                                'text-blue-600 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500': selectedIndex === index}">
                        <i class="w-4 h-4 mr-2 fa"
                           :class="{
                               'text-blue-600 dark:text-blue-500': selectedIndex === index,
                               'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300': selectedIndex !== index,
                               ['fa-' + tab.props.icon]: tab.props.icon !== undefined,
                               'fa-user': tab.props.icon === undefined
                           }"
                           aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                           viewBox="0 0 20 20">
                            <path
                                d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </i>
                        {{ tab.props.title }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <slot></slot>


</template>

<script setup lang="ts">

import {computed, onMounted, provide, ref, useSlots, VNode, watch} from 'vue';
import {TabProps} from '../../Types/tabs';

const slots = useSlots();

const props = withDefaults(defineProps<{
    tabId?: string;
    modelValue?: number|null;
}>(), {
    tabId: '',
    modelValue: null
});

const selectedIndex = ref<number>(0);
const count = ref<number>(0);
const tabs = computed<VNode<TabProps>[]>(() => {
    let tabsToReturn = [];
    if (slots.default) {
        tabsToReturn.push(...getTabs(slots.default()));
    }
    return tabsToReturn;
});

const getTabs = (slots): VNode<TabProps>[] => {
    let tabsToReturn = [];
    if (slots) {
        if (Array.isArray(slots)) {
            for (let i in slots) {
                tabsToReturn.push(...getTabs(slots[i]));
            }
        } else if (slots?.type?.__name === 'VTab') {
            tabsToReturn.push(slots);
        } else if (slots?.children) {
            tabsToReturn.push(...getTabs(slots.children));
        }
    }
    return tabsToReturn;
};


provide(props.tabId + 'TabsProvider', {
    selectedIndex,
    tabs,
    count
});

// loadHref allows us to not load on the first selection.
const selectTab = (i: number, loadHref: boolean = true) => {
    let tab = tabs.value[i];
    if(tab) {
        if(tab.props?.href && loadHref) {
            window.location.href = tab.props?.href;
        } else {
            // if(i !+= selectedIndex.value) {
            selectedIndex.value = i;
            emit('update:modelValue', i);
            // }
        }
    }
};

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: number): void
}>();

onMounted(() => {
    let index = props.modelValue;
    if(index === null || index === undefined) {
        // Try and find the matching tab from the URL
        // Get the hash from the URL
        let hash = window.location.hash;
        if(hash) {
            // Remove the # from the hash
            hash = hash.substring(1);
            // Find the tab with the matching href
            for(let i in tabs.value) {
                let tabId = tabs.value[i].props?.id;
                if(tabId === null || tabId === undefined) {
                    tabId = tabs.value[i].props?.title
                        .replace(/[\s_]+/g, '-')
                        .toLowerCase();
                }
                if(tabId === hash && tabs.value[i].props?.disabled === false) {
                    index = parseInt(i);
                    break;
                }
            }
        }
    }
    if(index === null || index === undefined) {
        // Fallback to the first tab
        index = 0;
    }
    selectTab(index, false);
});

watch(() => props.modelValue, (val) => {
    selectTab(val ?? 0, false);
});

</script>

<style scoped>

</style>
