<template>
    <div v-if="lineAtTop" class="pt-2 mt-2 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700">

    </div>
    <div v-if="props.title" class="pl-2 pb-2">
        <span class="text-gray-600 font-weight-light tracking-tight text-sm uppercase">{{title}}</span>
    </div>
    <ul class="space-y-2 font-medium">
        <slot></slot>
    </ul>
</template>

<script setup lang="ts">

const props = withDefaults(
    defineProps<{
    lineAtTop?: boolean;
    title?: string|null
}>(), {
        title: null
    });

</script>

<style scoped>

</style>
