<template>

    <div :class="classes" class="rounded relative text-left flex flex-row justify-between" role="alert" v-if="visible">
        <div class="flex flex-col w-full">
            <strong v-if="title" class="font-bold">{{ title }}</strong>
            <div class="block sm:inline">
                <slot>{{ text }}</slot>
            </div>
        </div>
        <div></div>
        <span v-if="dismissable">
            <svg class="fill-current h-6 w-6" :class="closeClasses" role="button"
                 @click="hide"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path
                     d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
    </div>

</template>

<script setup lang="ts">


import {computed, ref} from 'vue';

const props = withDefaults(defineProps<{
    title?: null|string,
    text?: string,
    colour?: 'green' | 'red' | 'blue' | 'yellow' | 'orange'
    dismissable?: boolean,
    compact?: boolean,
}>(), {
    colour: 'red',
    dismissable: true,
    compact: false,
});

const visible = ref<boolean>(true);

const hide = () => {
    visible.value = false;
    emit('dismiss');
};

const emit = defineEmits<{
    (event: 'dismiss'): void;
}>();

const classes = computed<string>(() => {
    let classes = '';

    if(props.compact) {
        classes += 'px-4 py-3';
    } else {
        classes += 'px-1 py-1';
    }

    if (props.colour === 'green') {
        classes += ' bg-green-100 border border-green-400 text-green-700';
    }
    else if (props.colour === 'blue') {
        classes += 'bg-blue-100 border border-blue-400 text-blue-700';
    }
    else if (props.colour === 'yellow') {
        classes += 'bg-yellow-100 border border-yellow-400 text-yellow-700';
    } else if (props.colour === 'orange') {
        classes += 'bg-orange-100 border border-orange-400 text-orange-700';
    } else {
        classes += 'bg-red-100 border border-red-400 text-red-700';
    }

    return classes;
});

const closeClasses = computed<string>(() => {
    if (props.colour === 'green') {
        return 'text-green-700';
    }
    else if (props.colour === 'blue') {
        return 'text-blue-700';
    }
    else if (props.colour === 'yellow') {
        return 'text-yellow-700';
    }
    else {
        return 'text-red-700';
    }
});

</script>

<style scoped>

</style>
