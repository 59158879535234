<template>
    <div class="flex flex-row">
        <v-select :id="id" v-model="value" :options="shortOptions">

        </v-select>

    </div>
</template>

<script lang="ts" setup>
import {SelectOption} from '../../../../../../resources/js/Types/forms';
import {computed} from 'vue';
import isString from 'lodash/isString';

const props = defineProps<{
    id: string
    options: SelectOption[]
    modelValue: SelectOption|null
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue.value;
    },
    set(value) {
        if(isString(value)) {
            value = props.options.find((e) => e.value === value);
        }
        emit('update:modelValue', value);
    }
});

const shortOptions = computed<SelectOption[]>(() => {
    return props.options.map((option: SelectOption) => {
        return {label: option.value, value: option.value};
    });
});

</script>

<style scoped>

</style>