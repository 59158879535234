<template>

    <div class="flex flex-row">
        <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
            <ul class="flex flex-col flex-wrap -mb-px text-sm font-medium align-left text-gray-500 dark:text-gray-400">
                <li class="mr-2"
                    v-for="(tab, index) in tabs"
                    :key="index"
                    @click="selectTab(index)">
                    <a v-if="tab.props.disabled" href="#"
                       class="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">
                        {{tab.props.title}}
                    </a>
                    <a v-else href="#"
                       class="inline-flex items-center justify-center p-4 border-b-2 group"
                       :class="{'border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': selectedIndex !== index,
                                'text-blue-600 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500': selectedIndex === index}">
                        <i class="w-4 h-4 mr-2 fa"
                           :class="{
                               'text-blue-600 dark:text-blue-500': selectedIndex === index,
                               'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300': selectedIndex !== index,
                               ['fa-' + tab.props.icon]: tab.props.icon !== undefined,
                               'fa-user': tab.props.icon === undefined}"
                           aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                           viewBox="0 0 20 20">
                            <path
                                d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </i>
                        {{ tab.props.title }}
                    </a>
                </li>
            </ul>
        </div>
        <slot></slot>
    </div>


</template>

<script setup lang="ts">

import {computed, onMounted, provide, ref, useSlots, VNode} from 'vue';
import {TabProps} from '../../Types/tabs';

const slots = useSlots();

const props = withDefaults(defineProps<{
    tabId?: string;
}>(), {
    tabId: ''
});

const selectedIndex = ref<number>(0);
const count = ref<number>(0);
const tabs = computed<VNode<TabProps>[]>(() => {
    let tabsToReturn = [];
    if(slots.default) {
        tabsToReturn.push(...getTabs(slots.default()));
    }
    return tabsToReturn;
});

const getTabs = (slots): VNode<TabProps>[] => {
    let tabsToReturn = [];
    if(slots) {
        if(Array.isArray(slots)) {
            for(let i in slots) {
                tabsToReturn.push(...getTabs(slots[i]));
            }
        } else if(slots?.type?.__name === 'VTab') {
            tabsToReturn.push(slots);
        } else if(slots?.children) {
            tabsToReturn.push(...getTabs(slots.children));
        }
    }
    return tabsToReturn;
};

provide(props.tabId + 'TabsProvider', {
    selectedIndex,
    tabs,
    count
});

const selectTab = (i: number) => {
    selectedIndex.value = i;
};

onMounted(() => {
    selectTab(0);
});

</script>

<style scoped>

</style>
