<template>
    <label :for="props.id" class="relative inline-flex items-center cursor-pointer">
        <input type="checkbox"
               :name="props.id"
               :id="props.id"
               :disabled="disabled"
               ref="checkboxRef"
               :aria-describedby="(props.hint ? props.id + '-hint' : null)"
               v-model="dynamicValue"
               class="sr-only peer">
        <span class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></span>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{label}}</span>
    </label>
    <p :id="props.id + '-hint'" v-if="props.hint" class="mb-4 text-sm text-gray-500 dark:text-gray-400">
        <span v-html="hint"></span>
    </p>
    <p :id="props.id + '-error'" v-if="error" class="mb-4 text-sm text-red-500 dark:text-gray-400">
        <i class="fa fa-triangle-exclamation pr-1"></i>
        <span v-html="error"></span>
    </p>
</template>

<script setup lang="ts">

import {computed, ref} from 'vue';

const props = withDefaults(defineProps<{
    id: string,
    onLabel: string,
    offLabel: string,
    hint?: string|null,
    modelValue?: boolean,
    error?: string|null,
    disabled?: boolean
}>(), {
    hint: null,
    modelValue: false,
    error: null,
    disabled: false
});

const label = computed<string>(() => {
    if(props.modelValue) {
        return props.onLabel;
    }
    return props.offLabel;
});

const checkboxRef = ref<HTMLInputElement|null>(null);

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: boolean): void
}>();
</script>

<style scoped>

</style>
