<template>
    <form @submit.prevent="search">
        <div class="flex flex-row justify-center w-full items-center">
            <div class="flex flex-col w-full">
                <v-text id="query" label="" v-model="dynamicQuery">

                </v-text>
            </div>
            <div class="flex flex-col ml-2">
                <v-button type="submit">
                    <i class="fa fa-search"></i>
                </v-button>
            </div>
            <div class="flex flex-col ml-2" v-if="dynamicQuery !== null">
                <v-button type="button" @click="clearQuery">
                    <i class="fa fa-times"></i>
                </v-button>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">

import {computed} from 'vue';

const props = defineProps<{
    modelValue: string|null;
}>();

const emit = defineEmits<{
    (event: 'update:modelValue', query: string|null): void,
    (event: 'search'): void
}>();

const dynamicQuery = computed<string|null>({
    get() {
        return props.modelValue;
    },
    set(value) {
        console.log(value);
        emit('update:modelValue', value);
    }
});

const clearQuery = () => {
    dynamicQuery.value = null;
    emit('search');
};

const search = () => {
    emit('search');
};

</script>

<style scoped>

</style>
