<template>
    <li>
        <button :class="{'text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700': !selected, 'bg-blue-100 text-white-900 dark:text-white hover:bg-blue-50 dark:hover:bg-gray-700': selected}"
                aria-controls="dropdown-example"
                class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" type="button"
                @click="expand = !expand">
            <i v-if="props.icon"
               :class="{['fa-' + props.icon]: true}"
               class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white fa"></i>
            <span class="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{{ text }}</span>
            <svg v-if="expand" aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 10 6"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5l4-4 4 4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
            <svg v-else aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                <path d="m1 1 4 4 4-4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>


        </button>
        <ul v-show="expand" id="dropdown-example" class="py-2 space-y-2 pl-4">
            <slot></slot>
        </ul>
    </li>

</template>

<script lang="ts" setup>

import {onMounted, ref} from 'vue';


const props = withDefaults(
    defineProps<{
      icon?: string | null;
      text?: string;
      selected?: boolean,
      expandedByDefault?: boolean,
    }>(), {
        icon: null,
        text: '',
        selected: false,
        expandedByDefault: false,
    });

onMounted(() => {
    expand.value = props.expandedByDefault ?? false;
});

const expand = ref<boolean>(false);

onMounted(() => {
    if (props.expandedByDefault) {
        expand.value = true;
    } else {
        expand.value = props.selected ?? false;
    }
});
</script>

<style scoped>

</style>
