<template>
    <ul class="grid" v-for="option in options">
        <li>
            <input type="checkbox"
                   :disabled="option.disabled ?? false"
                   :id="props.id + '-' + option.id" :name="props.id" :value="option.value" v-model="dynamicValue" class="hidden peer" required>
            <label :for="props.id + '-' + option.id" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                <div class="block">
                    <div class="w-full text-lg font-semibold">
                        {{option.label}}
                    </div>
                    <div class="w-full" v-if="option.hint">{{ option.hint }}</div>
                </div>
                <div>
                    <i class="fa fa-ban" v-if="option.disabled"></i>
                    <svg v-else class="w-5 h-5 ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </div>
            </label>
            <p :id="id + '-error'" v-if="error" class="mb-4 text-sm text-red-500 dark:text-gray-400 flex flex-row items-center">
                <i class="fa fa-triangle-exclamation pr-1"></i>
                <span class="whitespace-pre" v-html="error"></span>
            </p>
        </li>
    </ul>
</template>

<script setup lang="ts">

import {computed} from 'vue';
import {CheckboxCardOption} from '../../types/Form';


const props = withDefaults(defineProps<{
    id: string,
    options: CheckboxCardOption[],
    modelValue?: (string|number)[],
    error?: string|null
}>(), {
    modelValue: []
});

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: (string|number)[]): void
}>();
</script>

<style scoped>

</style>
