<template>
    <div class="inline-flex items-center rounded-md text-xs font-medium ring-1 ring-inset" :class="statusClasses">
        <span>
            <slot />
        </span>
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { computed } from 'vue';

/**
 * Local dependencies
 */
import { LozengeProps } from './props';


/**
 * Props
 */
const props = defineProps<LozengeProps>();

/**
 * Computed properties
 */
const statusClasses = computed<string>(() => {
    switch (props.variant) {
    case 'error':
        return 'bg-red-50 px-1.5 py-0.5 # text-red-700 ring-red-600/10';
    case 'warning': 
        return 'bg-yellow-50 px-1.5 py-0.5 text-yellow-800 ring-yellow-600/20';
    case 'success': 
        return 'bg-green-50 px-1.5 py-0.5 text-green-700 ring-green-600/20';
    case 'info':
        return 'bg-blue-50 px-1.5 py-0.5 text-blue-700 ring-blue-700/10';
    default: 
        return 'bg-gray-50 px-1.5 py-0.5 text-gray-600 ring-gray-500/10';
    }
});
</script>
