export interface Column {
    label: string,
    key: string,
    dataKey?: string,
    type: CellTypes,
    highlight?: boolean|null,
    sortable?: boolean;
    filterable?: boolean;
    currentlyFiltering?: boolean;
    groupable?: boolean;
}

export enum CellTypes {
    Text = 'text',
    Boolean = 'boolean',
    Date = 'date',
    DateAndTime = 'dateAndTime',
    Link = 'link',
    DateAndTimeWithSeconds = 'dateAndTimeWithSeconds',
}
