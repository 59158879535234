<template>
    <div class="flex flex-row w-full sm:min-w-64">
        <div class="flex flex-col w-full">
            <v-select :searchable="true"
                      v-model="dynamicCountry"
                      :options="countryOptions"
                      :label="props.label"
                      :hint="props.hint"
                      :error="props.error"
                      :loading="store.loading"
                      :required="props.required"
                      :id="props.id">

            </v-select>
        </div>

        <!--        <v-modal header="Kit Builder" ref="modal">-->
        <!--            <template #activator="{show}">-->
        <!--                <v-tooltip text="Open Kit Builder" position="left">-->
        <!--                    <template #activator>-->
        <!--                        <v-icon-button @click="show" class="mt-4">-->
        <!--                            <i class="fa fa-hammer px-2"></i>-->
        <!--                        </v-icon-button>-->
        <!--                    </template>-->
        <!--                </v-tooltip>-->
        <!--            </template>-->
        <!--            <div>-->
        <!--            </div>-->
        <!--        </v-modal>-->

    </div>

</template>

<script lang="ts" setup>

import {computed, onMounted} from 'vue';
import VSelect from '../Select/VSelect.vue';
import {SelectOption} from '../../Types/forms';
import {useCountryStore} from '../../store/stores/countryStore';

const props = withDefaults(
    defineProps<{
        label: string,
        id: string,
        modelValue: number|null,
        hint?: string|null,
        error?: string|null,
        required?: boolean,
  }>(), {
        modelValue: null,
        hint: null,
        error: null,
        required: false,
    }
);

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: number|null): void
}>();

const store = useCountryStore();

const countryOptions = computed<SelectOption[]>(() => {
    return store.countries.map(country => {
        return {
            label: country.name,
            value: country.id
        };
    });
});

onMounted(() => {
    store.loadCountries();
});

const dynamicCountry = computed<number|null>({
    get() {
        return props.modelValue ?? null;
    },
    set(value: number|null) {
        emit('update:modelValue', value);
    }
});

</script>

<style scoped>

</style>
