<template>
    <div class="w-full p-3 bg-white border border-gray-200 rounded-lg mb-3">
        <div class="flex gap-x-4 justify-between items-start border-b border-gray-200 pb-4">
            <div class="flex flex-col items-start justify-start">
                <h3 class="font-semibold text-gray-900 text-md">
                    {{ `Dispatch Request #${request.reference}` }}
                </h3>
                <time 
                    class="text-xs text-gray-400"
                    :datetime="request.created_at"
                >
                    {{ moment(request.created_at).format('DD/MM/YYYY @ HH:mm') }}
                </time>
            </div>
            <VLozenge :variant="calculateDispatchRequestLozengeVariant(request.status)">{{ startCase(request.status) }}</VLozenge>
        </div>

        <dl class="divide-y divide-gray-200 text-sm">
            <div class="flex justify-between gap-x-4 py-4" v-if="arrayWithValues(request.dispatch_request_items)">
                <dt class="w-1/3 text-gray-400">
                    Items
                </dt>
                <dd class="w-2/3 text-gray-900 flex flex-wrap leading-tight">
                    <span 
                        class="w-full block text-right" 
                        v-for="item in request.dispatch_request_items"
                    >
                        {{ `${item.quantity} x ${item.sku}` }}
                    </span>
                </dd>
            </div>

            <div class="flex justify-between gap-x-4 py-4" v-if="!minified">
                <dt class="w-1/3 text-gray-400">
                    ID
                </dt>
                <dd class="w-2/3 text-gray-900 flex flex-wrap leading-tight">
                    <span class="w-full block text-right">
                        {{ request.id }}
                    </span>
                </dd>
            </div>
        </dl>

        <div class="rounded-b-lg border-t border-gray-200 flex divide-x justify-center -mr-4 -ml-4 -mb-4 mt-auto" v-if="!minified">
            <a 
                :href="`/tools/watson#/share?model=dispatchRequest&id=${request.id}&reference=${request.reference}`"
                class="inline-flex items-center justify-center gap-x-2 py-2 font-semibold grow"
            >
                <i class="fa-regular fa-eye w-5 text-gray-400" aria-hidden="true"></i>
                <span>VIEW FULL REQUEST</span>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * External dependencies
 */
import startCase from 'lodash/startCase';
import moment from 'moment';

/**
 * Local dependencies
 */
import { LozengeProps } from '../Lozenge/props';
import { arrayWithValues } from '../../Helpers/array-utils';
import { DispatchRequest, DispatchRequestStatus } from './Types/dispatch-requests';

withDefaults(defineProps<{
    request: DispatchRequest;
    minified?: boolean;
}>(), {
    minified: true
});

// Functions

/**
 * Calculate Dispatch Status Lozenge Variant
 * 
 * Translates the current dispatch status into a variant that defines the colour and styling of the
 * status lozenge shown.
 * 
 * @param {string} dispatchStatus The dispatch status to translate into a lozenge variant.
 */
const calculateDispatchRequestLozengeVariant = (dispatchRequestStatus: DispatchRequestStatus): LozengeProps['variant'] => {
    switch (dispatchRequestStatus) {
    case 'Approved':
        return 'success';
    case 'Uploaded':
    case 'Pending':
        return 'info';
    case 'Out of Stock':
    case 'Holding':
    case 'Unknown':
        return 'warning';
    case 'Cancelled':
    case 'Upload Error':
        return 'error';
    default: 
        return 'default';
    }
};
</script>
