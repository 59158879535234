<template>
    <v-field-wrapper :id="props.id" :disabled="isDisabledState" :error="error" :hiddenLabel="hiddenLabel" :hint="hint"
                     :label="label">
        <template #default="{bind}">
            <div class="relative">
                <div class="flex flex-col">
                    <div class="flex flex-row">
                        <span
                            v-if="prefix"
                            class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                            <slot name="prefix">
                                {{ prefix }}
                            </slot>
                        </span>
                        <input
                            :id="props.id"
                            v-model="dynamicValue"
                            :class="[
                                (pale || isDisabledState) ? 'text-gray-400  dark:text-gray-800' : 'text-gray-900  dark:text-white',
                                prefix? 'rounded-none block flex-1' : '',
                                !prefix ? 'rounded-s-md' : '',
                                !postfix ? 'rounded-e-md' : '',
                            ]"
                            :disabled="isDisabledState"
                            :name="props.id"
                            class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            type="number"
                            v-bind="bind"
                        >
                        <span
                            v-if="postfix"
                            class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-s-0 border-gray-300 border-s-0 rounded-e-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                            {{ postfix }}
                        </span>
                    </div>
                    <div class="flex flex-row flex-wrap">
                        <div v-if="suggestions.length > 0">
                            <div class="flex flex-row flex-wrap">
                                <v-button v-for="suggestion in suggestions" :key="suggestion"
                                          class="text-sm mr-1 mt-1" @click="dynamicValue = suggestion">{{ suggestion }}
                                </v-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="props.loading"
                     class="absolute top-0 start-0 w-full h-full bg-white/[.5] rounded-lg dark:bg-gray-800/[.4]"></div>

                <div v-if="props.loading"
                     class="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div
                        aria-label="loading"
                        class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                        role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </template>
    </v-field-wrapper>
</template>

<script lang="ts" setup>

import {computed} from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';

const props = withDefaults(defineProps<{
    id: string,
    label: string,
    hiddenLabel?: boolean;
    hint?: string | null,
    modelValue?: number | null,
    pale?: boolean | null,
    error?: string | null,
    disabled?: boolean,
    suggestions?: number[],
    loading?: boolean,
    prefix?: string | null;
    postfix?: string | null;
}>(), {
    pale: false,
    hiddenLabel: false,
    hint: null,
    modelValue: null,
    error: null,
    disabled: false,
    suggestions: [],
    loading: false
});

const isDisabledState = computed<boolean>(() => props.disabled || props.loading);

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(value: number | null | '') {
        if (value === '') {
            value = null;
        }
        emit('update:modelValue', value);
    }
});

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: number): void
}>();
</script>

<style scoped>

</style>
